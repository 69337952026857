/* eslint-disable import/no-cycle */
import safeJsonStringify from "safe-json-stringify";
import contentfulClientGraphQLI from "../ContentfulGraphQLi";
import { IGraphQlQueryOption } from "../ContentfulGraphQLi/@types";
import { MessageBladeQuery, TMessagingBlade, TMessagingTypes } from "./@types";

export type TMessagesData = Record<TMessagingTypes, TMessagingBlade>;

export const getMessageData = async (
  renderType: TMessagingTypes[],
): Promise<TMessagesData> => {
  // const { items } = await contentfulClient.getEntries<TMessagingBlade>({
  //   content_type: "messagingBlade",
  //   "fields.type[in]": renderType.join(","),
  //   include: 10,
  // });
  const query: IGraphQlQueryOption = {
    query: MessageBladeQuery,
    variables: {
      type: renderType,
    },
  };
  const { messagingBladeCollection } = await contentfulClientGraphQLI(query);
  const messages = messagingBladeCollection?.items
    .filter((i: TMessagingBlade) => i?.type && renderType.includes(i?.type))
    .map((item: TMessagingBlade) => item);

  const serializedMessages: TMessagingBlade[] = JSON.parse(
    safeJsonStringify(messages),
  );
  const messageData: TMessagesData = {} as TMessagesData;
  serializedMessages.forEach((message) => {
    if (message.type) messageData[message.type] = message;
  });
  return messageData;
};
