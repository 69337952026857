import React, { FC } from "react";
import clsx from "clsx";
import { omit } from "lodash";

import HelperText from "../HelperText";
import Typo from "../../Typo";
import styles from "./textField.module.sass";

export interface TextFieldProps {
  valueKey: string;
  otherFieldProps?: Omit<
    | React.InputHTMLAttributes<HTMLInputElement>
    | React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    "type" | "name"
  >;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  helperText?: string;
  error?: string;
  rootClassName?: string;
  variant?: "outlined" | "standard";
  placeHolderColorBlack?: boolean;
  placeHolderItalics?: boolean;
  rows?: number;
  label?: string;
  ariaLabel?: string;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    valueKey,
    helperText,
    error,
    otherFieldProps,
    rootClassName,
    type,
    variant = "outlined",
    placeHolderColorBlack = false,
    placeHolderItalics = false,
    rows,
    label,
    ariaLabel,
  } = props;
  const textAreaDefualtProps = { rows };
  const defaultFieldProps = {
    type: type ?? "text",
    name: valueKey,
    "aria-label": ariaLabel ?? valueKey,
    placeholder: "placeholder",
    className: clsx(styles.textField, otherFieldProps?.className, {
      [styles.textFieldError]: !!error,
      [styles.standardTextField]: variant === "standard",
      [styles.textareaField]: !!rows,
      [styles.placeHolderBlack]: placeHolderColorBlack,
      [styles.placeHolderItalics]: placeHolderItalics,
    }),
    ...omit(otherFieldProps, "className"),
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = "inherit";

    // Get the computed styles for the element
    // const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      // parseInt(computed.getPropertyValue("border-top-width"), 10) +
      // parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight; //+
    // parseInt(computed.getPropertyValue("padding-bottom"), 10) +
    // parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    e.target.style.height = `${height}px`;

    otherFieldProps?.onChange?.(
      e as React.ChangeEvent<HTMLInputElement> &
        React.ChangeEvent<HTMLTextAreaElement>,
    );
  };

  return (
    <div className={clsx(styles.textFieldRoot, rootClassName, "textfield")}>
      {label && (
        <Typo normal classes="ml-1">
          {label}
        </Typo>
      )}
      {rows ? (
        <textarea
          {...omit(
            defaultFieldProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            "onChange",
          )}
          {...textAreaDefualtProps}
          onChange={handleChange}
        />
      ) : (
        <input
          {...(defaultFieldProps as React.InputHTMLAttributes<HTMLInputElement>)}
        />
      )}
      {(helperText || error) && (
        <HelperText error={!!error}>{error ?? helperText}</HelperText>
      )}
    </div>
  );
};

export default TextField;
