/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import { IDownloadableProps } from "../../models/Contentful/types";
import Button from "../Button";

interface CDownloadableProps extends IDownloadableProps {
  // eslint-disable-next-line react/require-default-props
  classes?: string;
}

const DownloadableLink: FC<CDownloadableProps> = ({
  name,
  file,
  classes = "",
}) => {
  const { fields: media } = file;

  const downloadHandler = () => {
    // if (isPrivate && (!appUser || !isMember)) {
    //   return showModal(<MemberOnlyModal />);
    // }
    window.location.href = media?.file?.url;
    return null;
  };
  return (
    <Button text onClick={downloadHandler} classes={classes}>
      {name ?? media?.file?.fileName ?? media?.file?.url}
      {/* <SvgLib icon="arrow" classes="is-inline" /> */}
    </Button>
  );
};

export default DownloadableLink;
