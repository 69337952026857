import React, { FC } from "react";
import Typo from "../Typo";
import { IHeaderProps } from "./@types";

const Header: FC<IHeaderProps> = ({ title, description }) => {
  return (
    <header className="has-text-centered mb-5">
      <Typo h2 classes="mb-4">
        {title}
      </Typo>
      <Typo normal>{description}</Typo>
    </header>
  );
};

export default Header;
