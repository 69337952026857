// eslint-disable-next-line import/no-cycle
import React, { FC } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import TextField from "../FormFields/TextField";
import { getFieldError } from "../FormFields/Utils";
import { TFormProps } from "../../models/Contact/@types";
import { IGetInvolvedFormProps } from "./@types";
import Messages from "./Messages";

const BaseFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(Messages.REQUIRED),
  email: Yup.string().email(Messages.INVALID_EMAIL).required(Messages.REQUIRED),
  organization: Yup.string().required(Messages.REQUIRED),
  industry: Yup.string().required(Messages.REQUIRED),
});

const FormValidationWithCommentSchema = BaseFormValidationSchema.shape({
  comment: Yup.string().required(Messages.REQUIRED),
});

interface TFormFieldConfig {
  placeHolder: string;
  label: string;
}

interface TFormFieldDataConfig {
  name: TFormFieldConfig;
  email: TFormFieldConfig;
  industry: TFormFieldConfig;
  organization: TFormFieldConfig;
  comment: TFormFieldConfig;
}

const DEFAULT_FORM_DATA: Record<keyof TFormFieldDataConfig, TFormFieldConfig> =
  {
    name: {
      placeHolder: "First and Last Name",
      label: "Name",
    },
    email: {
      placeHolder: "Enter Email",
      label: "Work Email",
    },
    industry: {
      placeHolder: "Enter Industry",
      label: "Industry",
    },
    organization: {
      placeHolder: "Company / School Name",
      label: "Company / School",
    },
    comment: {
      placeHolder: "Add your comments",
      label: "Comments",
    },
  };

export interface IFormProps extends IGetInvolvedFormProps {
  formData?: Partial<Record<keyof TFormFieldDataConfig, TFormFieldConfig>>;
  isCommentOptional?: boolean;
}

const Form: FC<IFormProps> = ({
  formData,
  onSubmit,
  isCommentOptional = false,
}) => {
  const formConfigData = { ...DEFAULT_FORM_DATA, ...formData };

  return (
    <div className="form-container">
      <Formik<TFormProps>
        onSubmit={(val) => onSubmit(val)}
        initialValues={{} as TFormProps}
        validationSchema={
          isCommentOptional
            ? BaseFormValidationSchema
            : FormValidationWithCommentSchema
        }
        enableReinitialize
      >
        {(formikProps: FormikProps<TFormProps>) => {
          const errors = {
            name: getFieldError("name", formikProps),
            email: getFieldError("email", formikProps),
            organization: getFieldError("organization", formikProps),
            industry: getFieldError("industry", formikProps),
            comment: getFieldError("comment", formikProps),
          };
          return (
            <form onSubmit={formikProps.handleSubmit} id="get_involved_form">
              <div>
                <TextField
                  valueKey="name"
                  otherFieldProps={{
                    placeholder: formConfigData.name.placeHolder,
                    onChange: formikProps.handleChange,
                  }}
                  rootClassName="mb-5"
                  placeHolderColorBlack
                  label={formConfigData.name.label}
                  error={errors.name}
                />
              </div>
              <div>
                <TextField
                  valueKey="organization"
                  otherFieldProps={{
                    placeholder: formConfigData.organization.placeHolder,
                    onChange: formikProps.handleChange,
                  }}
                  rootClassName="mb-5"
                  placeHolderColorBlack
                  label={formConfigData.organization.label}
                  error={errors.organization}
                />
              </div>
              <div>
                <TextField
                  valueKey="industry"
                  otherFieldProps={{
                    placeholder: formConfigData.industry.placeHolder,
                    onChange: formikProps.handleChange,
                  }}
                  rootClassName="mb-5"
                  placeHolderColorBlack
                  label={formConfigData.industry.label}
                  error={errors.industry}
                />
              </div>
              <div>
                <TextField
                  valueKey="email"
                  otherFieldProps={{
                    placeholder: formConfigData.email.placeHolder,
                    onChange: formikProps.handleChange,
                  }}
                  rootClassName="mb-5"
                  placeHolderColorBlack
                  label={formConfigData.email.label}
                  error={errors.email}
                  type="email"
                />
              </div>
              <div>
                <TextField
                  valueKey="comment"
                  otherFieldProps={{
                    placeholder: formConfigData.comment.placeHolder,
                    onChange: formikProps.handleChange,
                  }}
                  rootClassName="mb-5"
                  placeHolderColorBlack
                  placeHolderItalics
                  label={formConfigData.comment.label}
                  error={errors.comment}
                  rows={4}
                />
              </div>
              <div className="has-text-centered">
                <Button type="submit" theme="Filled - Lime Green Gradient">
                  Submit
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Form;
