/* eslint-disable import/no-cycle */
import helpers from "../../utils/helpers";
import { isUserExist } from "./api";

const CACHED_EXIST_EMAIL: Record<string, boolean> = {};
export const isEmailExists = async (
  email: string,
): Promise<boolean | undefined> => {
  if (CACHED_EXIST_EMAIL[email] !== undefined) return CACHED_EXIST_EMAIL[email];
  const isValidEmail = helpers.isEmail(email);
  if (!isValidEmail) return undefined;
  const response = await isUserExist(email);
  CACHED_EXIST_EMAIL[email] = response.data;
  return response.data;
};

export default {};
