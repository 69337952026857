/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
import { FC } from "react";
import Button, { ThemeType } from "../Button";
import {
  CLinkProps,
  IDeepLinkingProps,
  IDownloadableProps,
  IEventHandlerProps,
  IExternalLinkProps,
  IInternalLinkProps,
  LinkType,
} from "../../models/Contentful/types";

import { AvailableIcons } from "../SvgLib/@types";
import DownloadableLink from "./DownloadableLink";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import helpers from "../../utils/helpers";
import styles from "./link.module.sass";

interface LinkComponentProps extends CLinkProps {
  classes?: string;
  showPrivateLinks?: boolean;
  children?: React.ReactNode;
  icon?: AvailableIcons;
  iconClass?: string;
  showLabel?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
}

const Link: FC<LinkComponentProps> = ({
  fields,
  sys,
  classes = "",
  children,
  showPrivateLinks,
  icon,
  iconClass,
  showLabel = true,
  onClick,
  ariaLabel,
}) => {
  const linkType: LinkType = sys?.contentType?.sys?.id as LinkType;
  if (linkType === "externalLinks")
    return (
      <ExternalLink
        {...(fields as IExternalLinkProps)}
        ariaLabel={ariaLabel}
        classes={classes}
        showName={showLabel}
      >
        {children}
      </ExternalLink>
    );
  if (linkType === "internalLinks") {
    return (
      <InternalLink
        {...(fields as IInternalLinkProps)}
        ariaLabel={ariaLabel}
        classes={classes}
        showPrivateLinks={showPrivateLinks}
        iconName={icon}
        iconClass={iconClass}
        onClick={onClick}
      >
        {children}
      </InternalLink>
    );
  }
  if (linkType === "downloadable")
    return (
      <DownloadableLink {...(fields as IDownloadableProps)} classes={classes} />
    );

  if (linkType === "eventHandler") {
    return (
      <Button
        ariaLabel={ariaLabel}
        theme={fields.layout as ThemeType}
        {...fields.icon?.fields}
        classes={classes}
        onClick={() =>
          helpers.eventEmitor((fields as IEventHandlerProps)?.event)
        }
      >
        {fields.text}
      </Button>
    );
  }

  if (linkType === "deepLinking") {
    return (
      <Button
        eventLink
        classes={` ${styles.deepLinkingClass} ${classes}`}
        underline
        onClick={() =>
          helpers.eventEmitor((fields as IDeepLinkingProps)?.linkType)
        }
      >
        {fields.text}
      </Button>
    );
  }

  return null;
};
export default Link;
