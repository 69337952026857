/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useMemo, useState } from "react";
import router from "next/router";
import { ApiResponse } from "models/@types";
import utils, { logger } from "../utils";
import { IS_USER_AUTHENTICATED, ROUTES } from "../Constants/variables";
import User, {
  UpdatePasswordFormData,
  DeleteAccountData,
  LoginFormData,
  ResetPasswordFormData,
  SignUpFormData,
} from "../models/auth/@types";
import UserModel from "../models/User";
import AuthModel from "../models/auth";
import ProjectModel from "../models/Project";

export interface ITwoFactor {
  email: string;
  isNewCode: boolean;
  isLocked: boolean;
  sso?: boolean;
}

export interface AuthContextProps {
  appUser?: User;
  challengeData?: Array<any>;
  fetchingUser?: boolean;
  login: (values: Partial<LoginFormData>) => Promise<ApiResponse<void>>;
  googleLogin: (token: string, authType: string) => Promise<void>;
  ssoLoggedIn?: string | null;
  redirected?: string | null;
  redirectLoad?: string | null;
  setAuthState: (type: string, value: string | null) => void;
  logout: () => Promise<void>;
  currentUser: () => Promise<User>;
  signUp: (values: Partial<SignUpFormData>) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (data: ResetPasswordFormData) => Promise<void>;
  setAppUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  changePassword: (data: UpdatePasswordFormData) => Promise<void>;
  deleteAccount: (data: DeleteAccountData) => Promise<void>;
  currentChallengeId?: string;
  setCurrentChallengeId: (challengeId: string) => void;
  challengeJoinedCheck?: (challengeId: string) => Promise<string>;
  challengeRedirect?: (challengeUrl: string, challengeId: string) => void;
  hasFeedbackNotification?: boolean;
  setFeedbackNotification: () => void;
  twoFactor?: ITwoFactor | null;
  setTwoFactor: React.Dispatch<React.SetStateAction<ITwoFactor | null>>;
  twoFactorLogin: ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }) => Promise<ApiResponse<void>>;
  setSSOLoggedIn: React.Dispatch<React.SetStateAction<string | null>>;
}

const AuthContext = React.createContext<AuthContextProps>({
  login: () => Promise.resolve({} as ApiResponse<void>),
  googleLogin: () => Promise.resolve(),
  setAuthState: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  currentUser: () => Promise.resolve({} as User),
  signUp: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setAppUser: () => {},
  changePassword: () => Promise.resolve(),
  deleteAccount: () => Promise.resolve(),
  setCurrentChallengeId: () => {},
  challengeJoinedCheck: () => Promise.resolve({} as string),
  challengeRedirect: () => {},
  setFeedbackNotification: () => {},
  twoFactor: null,
  setTwoFactor: () => {},
  twoFactorLogin: () => Promise.resolve({} as ApiResponse<void>),
  setSSOLoggedIn: () => {},
});

export const AuthContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User>();
  const [challengeData, setChallengeData] = useState<Array<any>>();
  const [fetchingUser, setFetchingUser] = useState<boolean>(true);
  const [challengeId, setChallengeId] = useState<string>();
  const [hasFeedbackNotification, setHasFeedbackNotification] =
    useState<boolean>(false);
  const [ssoLoggedIn, setSSOLoggedIn] = useState<string | null>();
  const [redirected, setRedirected] = useState<string | null>();
  const [redirectLoad, setRedirectLoad] = useState<string | null>();
  const [twoFactor, setTwoFactor] = useState<ITwoFactor | null>(null);

  // Controls loading state of authentication pages
  const setAuthState = (type: string, value: string | null) => {
    // Setting localStorage because SSO redirect erases state
    if (value) {
      localStorage.setItem(type, "true");
    } else {
      localStorage.removeItem(type);
    }
    if (type === "sso") {
      setSSOLoggedIn(value ? "true" : null);
    } else if (type === "redirected") {
      setRedirected(value ? "true" : null);
    } else if (type === "redirectLoad") {
      setRedirectLoad(value ? "true" : null);
    }
  };

  const logout = React.useCallback(async () => {
    await AuthModel.logout();
    setUser(undefined);
    localStorage.removeItem("challengeData");
    localStorage.removeItem("userChange");
    // localStorage.removeItem("userExpiration");
    utils.clearCookie("isUserAuthenticated");
    localStorage.removeItem("sso");
  }, []);

  const currentUser = React.useCallback(async () => {
    try {
      const challenges = localStorage.getItem("challengeData");
      // if challengeData has already been fetched, stop Join Challenges immediately
      if (challenges && challenges !== "undefined") {
        setChallengeData(JSON.parse(challenges));
        setFetchingUser(false);
      }
      // Making sure challengeData local storage is never undefined
      if (challenges === "undefined") {
        localStorage.setItem("challengeData", "[]");
      }
      const { pathname } = window.location;
      const splitPath = pathname.split("/");
      const authenticated = localStorage.getItem("isUserAuthenticated");

      if (
        authenticated ||
        (authenticated &&
          pathname.includes("challenges") &&
          // To make sure URL is a specific challenge URL, and to account for potential
          // absence of / at the end.
          splitPath.at(-1) !== "challenges" &&
          splitPath.at(-2) !== "challenges") ||
        pathname.includes("dashboard")
      ) {
        const userData = await AuthModel.getUser(pathname);
        const { success, data } = userData;
        if (
          (!challenges &&
            success &&
            data &&
            utils.getCookie("isUserAuthenticated") ===
              IS_USER_AUTHENTICATED.YES) ||
          localStorage.getItem("userChange")
        ) {
          setFetchingUser(true);
          const { challenges } = data;
          setUser(data);
          setChallengeData(challenges);
          localStorage.removeItem("userChange");
          // Get challenge data and save it in local storage, partly to prevent
          // the loading spinner on the Join Challenge button
          localStorage.setItem("challengeData", JSON.stringify(challenges));
          if (challenges && challenges.length > 0) {
            localStorage.setItem("challengeData", JSON.stringify(challenges));
          } else {
            localStorage.setItem("challengeData", "[]");
          }
          utils.setCookie("isUserAuthenticated", IS_USER_AUTHENTICATED.YES);
          setFetchingUser(false);
          return data;
        }
        if (success) {
          setFetchingUser(false);
          setUser(userData.data);
          return userData;
        }
        setFetchingUser(false);
        localStorage.removeItem("challengeData");
        // throw new Error("User Not Authenticated!");
        utils.setCookie("isUserAuthenticated", IS_USER_AUTHENTICATED.NO);
        return null;
      }
      setFetchingUser(false);
      return null;
    } catch (error: any) {
      logger.error(error);
      setFetchingUser(false);
      throw error;
    }
  }, []);

  const login = React.useCallback(
    async (values: Partial<LoginFormData>) => {
      try {
        const response = await AuthModel.login(values);
        if (
          response &&
          ["TWO_FACTOR_AUTH_REQUIRED"].includes(response.responseCode)
        ) {
          setTwoFactor({
            email: response.email as string,
            isNewCode: response.isNewCode as boolean,
            isLocked: response.isLocked as boolean,
          });
          return response;
        }
        utils.setCookie("isUserAuthenticated", IS_USER_AUTHENTICATED.YES);
        await currentUser();
        return response;
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [currentUser],
  );

  const twoFactorLogin = React.useCallback(
    async ({ email, code }: { email: string; code: string }) => {
      const response = await AuthModel.twoFactorSignIn({
        email,
        code,
      });

      return response;
    },
    [],
  );

  const googleLogin = async (token: string, authType: string) => {
    await AuthModel.getGoogleUser(token, authType);
  };

  const signUp = useCallback(async (values: Partial<SignUpFormData>) => {
    try {
      await AuthModel.signUp(values);
      utils.setCookie("isUserAuthenticated", IS_USER_AUTHENTICATED.YES);
    } catch (error) {
      logger.error(error);
      utils.setCookie("isUserAuthenticated", IS_USER_AUTHENTICATED.NO);
      throw error;
    }
  }, []);

  const forgotPassword = React.useCallback(async (email: string) => {
    try {
      await AuthModel.forgotPassword({ email });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }, []);

  const resetPassword = React.useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        await AuthModel.resetPassword(data);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [],
  );

  const changePassword = React.useCallback(
    async (data: UpdatePasswordFormData) => {
      try {
        await AuthModel.changePassword(data);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [],
  );

  const deleteAccount = React.useCallback(async (data: DeleteAccountData) => {
    try {
      router.push(ROUTES.HOME ?? "#").then(async () => {
        await AuthModel.deleteAccount(data);
        router.reload();
      });
    } catch (error) {
      logger.error(error);
    }
  }, []);

  const setCurrentChallengeId = (id: string) => {
    setChallengeId(id);
  };

  const challengeJoinedCheck = async (challengeId: string) => {
    try {
      const { data } = await ProjectModel.isChallengeJoined(challengeId);
      return data.projectId;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  const challengeRedirect = async (
    challengeId: string,
    challengeUrl: string,
  ) => {
    if (challengeUrl && challengeId) {
      const projectId = await challengeJoinedCheck(challengeId);
      if (!projectId) {
        localStorage.setItem("open_modal", "true");
      }
      router.push(challengeUrl);
      localStorage.removeItem("challengeId");
      localStorage.removeItem("last_url");
    }
  };

  const setFeedbackNotification = React.useCallback(async () => {
    try {
      if (user) {
        const { data } = await UserModel.hasFeedbackNotification();
        setHasFeedbackNotification(data);
      }
    } catch (error) {
      logger.error(error);
    }
  }, [user]);

  const memoedValue = useMemo(() => {
    return {
      appUser: user,
      challengeData,
      fetchingUser,
      login,
      googleLogin,
      logout,
      ssoLoggedIn,
      redirected,
      redirectLoad,
      setAuthState,
      currentUser,
      signUp,
      forgotPassword,
      resetPassword,
      setAppUser: setUser,
      changePassword,
      deleteAccount,
      currentChallengeId: challengeId,
      challengeJoinedCheck,
      setCurrentChallengeId,
      challengeRedirect,
      hasFeedbackNotification,
      setFeedbackNotification,
      twoFactor,
      setTwoFactor,
      twoFactorLogin,
      setSSOLoggedIn,
    } as AuthContextProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    challengeData,
    fetchingUser,
    login,
    logout,
    ssoLoggedIn,
    redirected,
    redirectLoad,
    setAuthState,
    currentUser,
    signUp,
    setUser,
    setCurrentChallengeId,
    challengeJoinedCheck,
    setFeedbackNotification,
    challengeId,
    twoFactor,
    twoFactorLogin,
    setSSOLoggedIn,
  ]);
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
