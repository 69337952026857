/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import ContentModalContext from "../../AppContext/ContentModalProvider";
import { IInformativeModalProps } from "../../blades/informativeModal/@types";
import InformativeModal from "../../blades/informativeModal/informativeModal";
import ModalWrapper from "../ModalWrapper";
import { CType } from "../../models/Contentful/types";

export const useProjectModal = (modal?: CType<IInformativeModalProps>) => {
  const { showModal, hideModal } = useContext(ContentModalContext);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const router = useRouter();

  const closeModal = () => {
    setIsModelOpen(false);
    hideModal();
  };

  useEffect(() => {
    if (isModelOpen) closeModal();
  }, [router.pathname]);

  const showCreateProjectModal = () => {
    modal &&
      showModal(
        <ModalWrapper isActive onClose={closeModal} hasCloseButton>
          <InformativeModal {...modal} />
        </ModalWrapper>,
      );
    modal && setIsModelOpen(true);
  };
  return {
    showCreateProjectModal,
  };
};

export default useProjectModal;
