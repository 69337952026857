export const Avatars = [
  {
    id: "avatar_1",
    img: "avatar-boybluehair.svg",
    name: "Boy with Blue Hair",
  },
  {
    id: "avatar_2",
    img: "avatar-boyglasses.svg",
    name: "Boy with Glasses",
  },
  {
    id: "avatar_3",
    img: "avatar-curlybluehair.svg",
    name: "Person with Curly Blue Hair",
  },
  {
    id: "avatar_4",
    img: "avatar-curlyboy.svg",
    name: "Boy with Curly Hair",
  },
  {
    id: "avatar_5",
    img: "avatar-default.svg",
    name: "Default Avatar",
  },
  {
    id: "avatar_6",
    img: "avatar-girlbluehair.svg",
    name: "Girl with Blue Hair",
  },
  {
    id: "avatar_7",
    img: "avatar-girllimehair.svg",
    name: "Girl with Lime Hair",
  },
  {
    id: "avatar_8",
    img: "avatar-hijab.svg",
    name: "Person with Hijab",
  },
  {
    id: "avatar_9",
    img: "avatar-purplehairgirl.svg",
    name: "Girl with Purple Hair",
  },
  {
    id: "avatar_10",
    img: "avatar-sunglasses.svg",
    name: "Person with Sunglasses",
  },
  {
    id: "avatar_11",
    img: "avatar-they.svg",
    name: "Person",
  },
];
export default Avatars;
