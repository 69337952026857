// eslint-disable-next-line import/no-cycle
import React, { FC } from "react";
import { IGetInvolvedFormProps } from "./@types";
import Form from "./Form";
import Header from "./Header";

const SuggestChallenge: FC<IGetInvolvedFormProps> = ({ onSubmit }) => {
  return (
    <div>
      <Header
        title="Suggest a Challenge"
        description="Have a great idea for a KnoPro Challenge? We want to hear it! Complete the form below to submit your suggestion for a full-scale Challenge, Skillbuilder, or any other project that would benefit KnoPro students."
      />
      <Form
        onSubmit={onSubmit}
        formData={{
          comment: {
            label: "What’s your Challenge idea?",
            placeHolder: "Share your idea in 3-4 sentences…",
          },
        }}
      />
    </div>
  );
};

export default SuggestChallenge;
