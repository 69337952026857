import { logger } from "../../utils";

import { IGraphQlQueryOption } from "./@types";

const GRAPHQL_API_URL = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_NAME}`;

const headers = {
  Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_KEY}`,
  "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "http://localhost:3000",
  //   "Access-Control-Allow-Headers": "*",
};

// axios.defaults.withCredentials = false;
// axios.defaults.baseURL = GRAPHQL_API_URL;
const contentfulClientGraphQLI = async (graphqlQuery: IGraphQlQueryOption) => {
  try {
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(graphqlQuery),
    };

    const response = await fetch(GRAPHQL_API_URL, options);
    const { data } = await response.json();
    return data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export default contentfulClientGraphQLI;
