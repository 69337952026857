import React, { FC } from "react";
import clsx from "clsx";

import ButtonGraphQL from "../../ButtonGraphQL";
import RichTextComponentGraphQL from "../../RichTextComponentGraphQL";
import SvgLib from "../../SvgLib";
import { TMessagingBlade } from "../../../models/Message/@types";
import Typo from "../../Typo";
import SectionWrapper from "../../SectionWrapper";
import Button, { ThemeType } from "../../Button";
import styles from "./authThankYou.module.sass";

export interface AuthThankYouProps extends TMessagingBlade {
  onYes?: () => void;
  inviteMore?: () => void;
}

const AuthThankYou: FC<AuthThankYouProps> = (props) => {
  const {
    heading,
    subHeading,
    icon,
    commonProperties,
    description,
    links,
    type,
    onYes,
    inviteMore,
  } = props;
  const parentBg =
    commonProperties?.backgroundColor ??
    commonProperties?.parentBackgroundColor;

  const btnTheme = links?.layout as ThemeType;
  const btnText = links?.text;

  return (
    <SectionWrapper
      hasBodyMargin={false}
      parentBackgroundColor={parentBg}
      // backgroundImage={commonProperties?.fields.backgroundImage}
      classes={styles.thanksRoot}
      childClasses={styles.thanksChildRoot}
    >
      {icon && <SvgLib icon={icon} classes="mb-4" iconSize="Large" />}
      {heading && (
        <Typo h2 classes={clsx("mb-2", "heading")}>
          {heading}
        </Typo>
      )}
      {subHeading && (
        <Typo h2 classes={clsx("mb-5", "sub-heading")}>
          {subHeading}
        </Typo>
      )}
      {description && (
        <Typo normal classes={clsx(styles.thanksSubtitle, "description")}>
          <RichTextComponentGraphQL
            json={description.json}
            links={description?.links}
          />
        </Typo>
      )}
      {type === "provide-feedback-thank-you" && onYes && (
        <Button
          theme="Filled - Lime"
          onClick={() => {
            onYes();
          }}
        >
          Review Requests
        </Button>
      )}
      {links && (
        <ButtonGraphQL
          theme={btnTheme}
          link={links}
          classes={styles.thanksHomeBtn}
        >
          {btnText}
        </ButtonGraphQL>
      )}
      {inviteMore && (
        <Button
          onClick={() => {
            inviteMore();
          }}
          classes="mt-5"
        >
          Invite More Team Members
        </Button>
      )}
    </SectionWrapper>
  );
};

export default AuthThankYou;
