import { TTimeStamp } from "../@types";

export interface LoginFormData {
  email: string;
  password: string;
  sso_id: string;
}

export interface TwoFactorFormData {
  code: string;
  email?: string;
}

export enum TRole {
  STUDENT = "student",
  TEACHER = "teacher",
  MENTOR = "mentor",
  ADMIN = "admin",
}

export default interface User extends TTimeStamp {
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  id: string;
  role: TRole;
  organization: string;
  bio?: string;
  avatar?: TAvatar;
  full_name?: string;
  blocked_at?: string;
  state?: string;
  industry?: string;
  city?: string;
  naf_academy?: string | boolean;
  challenges?: Array<object>;
}

export interface GoogleSSO extends User {
  condition: string;
  google_object: object;
  responseCode?: string;
  message?: string;
  code?: number;
}

export interface TStudent extends User {
  city: string;
  state: string;
}

export type TTeacher = TStudent;

export interface TMentor extends User {
  industry: string;
}

export interface SignUpFormData {
  role: TRole;
  first_name: string;
  last_name: string;
  organization: string;
  industry: string;
  city: string;
  state: string;
  email: string;
  password: string;
  confirmPassword: string;
  mentorCode?: string;
  is_policy_accepted: boolean;
  is_role_accepted: boolean;
  sso_type: string;
  sso_id: string;
  naf_academy: string;
  sign_up_type: string;
  ageMonth?: number;
  ageDay?: number;
  ageYear?: number;
}

export interface ForgotPasswordFormData {
  email: string;
}

export interface ResetPasswordFormData {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface UpdatePasswordFormData {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface DeleteAccountData {
  id: string;
}

export interface TAvatar {
  name: string;
  url: string;
}
