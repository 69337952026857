/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { createClient } from "contentful";
import safeJsonStringify from "safe-json-stringify";
import dayjs from "dayjs";
import contentfulClientGraphQLI from "../ContentfulGraphQLi";
import {
  IGraphQlQueryOption,
  PageTemplateQuery,
} from "../ContentfulGraphQLi/@types";
import { TabWidgetKeyArray } from "../Challenge/@types";

import { CType, Page, TPageData } from "./types";
import { TFilterQuery, TTagListType } from "./types/lists";
import { SiteTemplate } from "./types/siteTemplateGraphQL";

const contentfulClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_KEY ?? "",
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_NAME ?? "master",
});

const devContentfulPreviewClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_KEY ?? "",
  environment: "develop",
  host: "preview.contentful.com",
});
const masterContentfulPreviewClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_KEY ?? "",
  environment: "master",
  host: "preview.contentful.com",
});

let CACHED_SITE_DATA: SiteTemplate;

export const getSiteData = async (): Promise<{ site: SiteTemplate }> => {
  try {
    if (!CACHED_SITE_DATA) {
      const query: IGraphQlQueryOption = {
        query: PageTemplateQuery,
      };
      const { templateCollection } = await contentfulClientGraphQLI(query);

      CACHED_SITE_DATA = templateCollection?.items[0];
    }
    return {
      site: CACHED_SITE_DATA,
    };
  } catch (e) {
    return {
      site: {} as SiteTemplate,
    };
  }
};

const fetchNestedReferencePageSlugs: any = (pagesWidgets: CType<unknown>[]) => {
  pagesWidgets?.map((w) => {
    return (
      w?.fields &&
      Object.values(w.fields).forEach((value) => {
        if (value?.sys?.contentType?.sys?.id === "internalLinks") {
          Object.keys(value.fields.referencePage.fields).forEach((key) => {
            if (key !== "slug") delete value.fields.referencePage.fields[key];
          });
        }
        if (Array.isArray(value)) {
          value.forEach((v) => {
            if (
              v?.fields?.referencePage?.sys?.contentType?.sys?.id === "pages"
            ) {
              Object.keys(v.fields.referencePage.fields).forEach((key) => {
                if (key !== "slug") delete v.fields.referencePage.fields[key];
              });
            }
            fetchNestedReferencePageSlugs(value);
          });
        }
      })
    );
  });
  return pagesWidgets;
};

// const excludeTabKeys = (tabSlug: string, key: string) => {
//   const tabWidgetKey = [
//     "overview",
//     "explore",
//     "focus",
//     "imagine",
//     "create",
//     "pitch",
//   ];

//   const index = tabWidgetKey.indexOf(tabSlug);
//   if (index !== -1) {
//     tabWidgetKey.splice(index, 1);
//   }
//   return tabWidgetKey.includes(key);
// };

const parseUnnecessaryKeys: any = (data: any, contentType: string) => {
  if (typeof data === "object" && data !== null) {
    if (!Array.isArray(data)) {
      return Object.keys(data).reduce((previousValue, key) => {
        return [
          "metadata",
          "environment",
          "space",
          "revision",
          "locale",
        ].includes(key) ||
          (key === "type" && (data[key] === "Entry" || data[key] === "Link")) ||
          (contentType !== "challenge" && TabWidgetKeyArray.includes(key))
          ? previousValue
          : {
              ...previousValue,
              [key]: parseUnnecessaryKeys(data[key as keyof typeof data]),
            };
      }, {});
    }
    return data.map((item) => parseUnnecessaryKeys(item));
  }
  return data;
};

export const getPageData = async <T>(
  pageId: string,
  contentType?: string,
  tabSlug?: string,
  hasNestedTab?: string,
  pageOptions?: object,
): Promise<TPageData<T>> => {
  const site = await getSiteData();
  const safeSiteData = JSON.parse(safeJsonStringify(site));
  try {
    // if (pageId === "testing-graphql") {
    //   const query: IGraphQlQueryOption = {
    //     // operationName: "GetPageData",
    //     query: PageDataQuery,
    //     variables: {
    //       slug: pageId,
    //     },
    //   };
    //   const { pagesCollection } = await contentfulClientGraphQLI(query);
    //   const updatedDate = dayjs(
    //     pagesCollection.items[0]?.sys?.publishedAt ?? ""
    //   ).format("YYYY-MM-DD");
    //   pagesCollection.items[0].entryId = pagesCollection.items[0]?.sys?.id;
    //   pagesCollection.items[0].updatedDate = updatedDate;
    //   const { sys, ...rest } = pagesCollection.items[0];
    //   return {
    //     site: safeSiteData?.site,
    //     page: JSON.parse(safeJsonStringify(rest)),
    //   };
    // }

    const pageData = await contentfulClient.getEntries<Page & T>({
      content_type: contentType ?? "pages",
      "fields.slug": pageId,
      include: 10,
      ...pageOptions,
    });
    const updatedDate = dayjs(pageData.items[0]?.sys?.updatedAt ?? "").format(
      "YYYY-MM-DD",
    );
    const seo = pageData.items[0]?.fields?.seoMetadata;
    const pagesWidgets = pageData.items[0].fields.widget;
    const ref = pagesWidgets && fetchNestedReferencePageSlugs(pagesWidgets);
    delete pageData.items[0].fields.widget;

    pageData.items[0].fields.entryId = pageData.items[0]?.sys?.id;
    pageData.items[0].fields.updatedDate = updatedDate;
    pageData.items[0].fields.widget = ref;
    pageData.items[0].fields.seoMetadata = seo;
    const safePageData = JSON.parse(
      safeJsonStringify(pageData.items[0]?.fields),
    );
    const newPageData = parseUnnecessaryKeys(
      safePageData,
      contentType,
      tabSlug,
      hasNestedTab,
    );
    return {
      site: safeSiteData?.site,
      page: newPageData,
    };
  } catch (e) {
    return {
      site: safeSiteData?.site,
      page: {} as Page & T,
    };
  }
};

export const getTagList = async (
  contentType: TTagListType,
  key = "title",
): Promise<string[]> => {
  const contentModel = await contentfulClient.getContentType(contentType);
  const fieldItem = contentModel.fields.find((field) => field.id === key);
  if (!fieldItem) return [];
  const tagsList = (fieldItem?.items?.validations?.[0] as any)?.in;
  return tagsList ?? [];
};

export const getList = async <T>(
  contentType: string,
  skip = 0,
  limit = 4,
  filter: TFilterQuery = {},
): Promise<{ total: number; items: CType<T>[] }> => {
  const result = await contentfulClient.getEntries<T>({
    content_type: contentType,
    limit,
    skip,
    ...filter,
    include: 5,
  });
  return { total: result.total, items: result?.items ?? [] };
};

export interface ContentfulEntries {
  total: number;
  items: any[];
}

export const getAllContentEntries = async <T>(
  request: any,
): Promise<ContentfulEntries> => {
  try {
    const entries: ContentfulEntries = {
      total: 0,
      items: [],
    };
    let fetched = 0;
    const limit = 100;
    do {
      // eslint-disable-next-line no-await-in-loop
      const contentEntries = await contentfulClient.getEntries<
        T & {
          slug: any;
        }
      >({
        ...request,
        select: ["fields.slug"],
        skip: fetched,
        limit,
      });
      fetched += contentEntries.items.length;
      entries.total = contentEntries.total;
      entries.items = [...entries.items, ...contentEntries.items];
    } while (fetched < entries.total);

    console.log(
      `Fetched ${entries.items.length} entries for ${request.content_type}.`,
    );
    return entries;
  } catch (e) {
    console.log("Skipping Build of page due to error");
    return { total: 0, items: [] };
  }
};

export default contentfulClient;
export { devContentfulPreviewClient, masterContentfulPreviewClient };
