// eslint-disable-next-line import/no-cycle
import React, { FC } from "react";
import { IGetInvolvedFormProps } from "./@types";
import Form from "./Form";
import Header from "./Header";

const BecomePartner: FC<IGetInvolvedFormProps> = ({ onSubmit }) => {
  return (
    <div>
      <Header
        title="Become a Partner"
        description="Take the first step towards making a difference. Please complete the form below and a member of our team will reach out to you shortly with details on partnership and sponsorship opportunities at KnoPro."
      />
      <Form
        onSubmit={onSubmit}
        formData={{
          comment: {
            label: "Comments",
            placeHolder: "Add your comments",
          },
        }}
      />
    </div>
  );
};

export default BecomePartner;
