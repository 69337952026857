/* eslint-disable import/no-cycle */
import { Document } from "@contentful/rich-text-types";
import { ITagList } from "../Contentful/types/lists";
import { ChallengeNSkillBuilderStatusForUser } from "../User";
import { IInformativeModalProps } from "../../blades/informativeModal/@types";
import { CMedia, CType } from "../Contentful/types";
import { IPartnerCardProps } from "../Partner/@types";
import { SeoMetaDataGraphQL } from "../Contentful/types/siteTemplateGraphQL";

export interface IChallengeProps {
  title: string;
  description: Document;
  media: "Image" | "Video";
  image?: CMedia;
  videoUrl?: string;
  // skillName: string[];
  skills: CType<ITagList>;
  // industry: string;
  industryList: CType<ITagList>;
  earnPoints: number;
  award?: string;
  dueDate: string;
  duration: string;
  partners: CType<IPartnerCardProps>[];
  slug: string;
  entryId: string;
  widget?: CType<unknown>[];
  modals: CType<IInformativeModalProps>[];
  launchDate: string;
  overview?: CType<unknown>[];
  explore?: CType<unknown>[];
  focus?: CType<unknown>[];
  imagine?: CType<unknown>[];
  create?: CType<unknown>[];
  pitch?: CType<unknown>[];

  // For UI implmentation
  currentTab?: IChallengeTab;

  project_id?: string;
  status?: ChallengeNSkillBuilderStatusForUser;
  challenge_id?: string;
  isPreview?: boolean;
}

export enum TChallengeModalSubType {
  CREATE_PROJECT = "Create Project",
  CREATE_ACCOUNT = "Create Account",
}

export type TabWidgetKeys = keyof Pick<
  IChallengeProps,
  "overview" | "explore" | "focus" | "imagine" | "create" | "pitch"
>;

export const TabWidgetKeyArray = [
  "overview",
  "explore",
  "focus",
  "imagine",
  "create",
  "pitch",
];

export interface IChallengeTab {
  name: string;
  slug: string;
  widgetKey: TabWidgetKeys;
}

export type ChallengeButtonTypes =
  | "Active: Go To Project"
  | "Join Challenge"
  | "Completed"
  | "Project Submitted"
  | "Loading Challenge Detail";

export interface ITabItemsCollection {
  __typename: string;
  slug: string;
}
export interface IChallengeWidgetItems {
  __typename: string;
  tabItemsCollection?: {
    items: ITabItemsCollection[];
  };
}

interface IChallengeWidgetDataItems {
  items: IChallengeWidgetItems[];
}

export interface IChallengeQueryDataItems {
  sys: {
    publishedAt: string;
  };
  slug: string;
  seoMetadata: SeoMetaDataGraphQL;
  overview: IChallengeWidgetDataItems;
  explore: IChallengeWidgetDataItems;
  focus: IChallengeWidgetDataItems;
  imagine: IChallengeWidgetDataItems;
  create: IChallengeWidgetDataItems;
  pitch: IChallengeWidgetDataItems;
}

export interface IChallengeQueryData {
  items: IChallengeQueryDataItems[];
}

export const ChallengeFilterOutInternals = `query GetChallengeSlugs{
  challengeCollection (where: { slug_not_contains: "internalversion" }){
    items {
      sys {
        id
      }
      slug,
      title
    }
  }
}`;

export const ChallengeSlugNamesQuery = `query GetChallengeSlugs{
  challengeSlugs: challengeCollection{
    items {
      slug
    }
  }
}`;

export const ChallengeNestedSlugs = `query GetChallengeNestedSlugs($slug: String!){
  challengeCollection(where:{slug:$slug},limit:1){
    items{
      sys{
        publishedAt
      },
      slug,
      overview: overviewCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      explore: exploreCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      focus: focusCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      imagine: imagineCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      create: createCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      pitch: pitchCollection{
        items{
          __typename,
          ... on TabsBlade{
            tabItemsCollection(limit:10){
              items{
                __typename,
                slug
              }
            }
          }
        }
      },
      seoMetadata: seoMetadata {
        __typename
        noIndex
        title
        description
        keywords
        noIndex
        noFollow
        canonicalUrl
        type
        card
        creator
        site
        siteName
        url
      }
    }
  }
}
`;
