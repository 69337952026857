/* eslint-disable import/no-cycle */
import { ApiResponse, ApiResponseList, TList } from "../@types";
import { TProject, TScoring, TUserProject } from "../User";
import { request } from "../../utils";
import {
  TChallengeJoinedCheck,
  TCreateProject,
  TFeedback,
  TProjectInvitees,
  TProjectSubmission,
  TProvideFeedback,
  TRequestFeedback,
  TSurveySummary,
} from "./@types";

export const createProjects = async (
  challengeId: string,
): Promise<ApiResponse<TCreateProject>> => {
  localStorage.setItem("userChange", "true");
  return request<ApiResponse<TCreateProject>>({
    url: `project`,
    method: "POST",
    data: { challengeId },
  });
};

export const isChallengeJoined = async (
  challengeId: string,
): Promise<ApiResponse<TChallengeJoinedCheck>> => {
  return request<ApiResponse<TChallengeJoinedCheck>>({
    url: `project/challenge-detail/${challengeId}`,
    method: "GET",
  });
};

export const getProject = async (
  projectId: string,
): Promise<ApiResponse<TProject>> => {
  commentSeen(projectId);
  return request<ApiResponse<TProject>>({
    url: `project/${projectId}`,
    method: "GET",
  });
};

export const getProjectMembers = async (
  projectId: string,
): Promise<ApiResponse<TProject>> => {
  commentSeen(projectId);
  return request<ApiResponse<TProject>>({
    url: `project/${projectId}`,
    method: "GET",
  });
};

export const getProjectInvitees = async (
  projectId: string,
): Promise<ApiResponse<TProjectInvitees[]>> => {
  commentSeen(projectId);
  return request<ApiResponse<TProjectInvitees[]>>({
    url: `project/${projectId}/get-project-invitees`,
    method: "GET",
  });
};

export const commentSeen = async (
  projectId: string,
): Promise<ApiResponse<TProject>> => {
  return request<ApiResponse<TProject>>({
    url: `comment/${projectId}/seen`,
    method: "GET",
  });
};

export const getProjectIds = async (): Promise<ApiResponseList<string>> => {
  return request<ApiResponseList<string>>({
    url: "project/getIds",
    method: "GET",
  });
};

export const sendInvite = async (
  projectId: string,
  invitees: string[],
  challengeId: string,
  inviterId?: string,
) => {
  return request({
    url: `project/${projectId}/invite-users`,
    method: "POST",
    data: { invitees, challengeId, inviterId },
  });
};

export const acceptInvite = async (
  projectId: string,
  token: string,
): Promise<ApiResponse<TUserProject>> => {
  localStorage.setItem("userChange", "true");
  return request<ApiResponse<TUserProject>>({
    url: `project/${projectId}/accept-invite`,
    method: "POST",
    params: { token },
  });
};

export const getTeamCount = async (
  projectId: string,
): Promise<ApiResponse<number>> => {
  return request<ApiResponse<number>>({
    url: `project/${projectId}/team-count`,
    method: "GET",
  });
};

export const removeUser = async (
  projectId: string,
  userId: string,
): Promise<ApiResponse<boolean>> => {
  return request<ApiResponse<boolean>>({
    url: `project/${projectId}/remove-user/${userId}`,
    method: "DELETE",
  });
};

export const removeInvitee = async (
  projectId: string,
  userEmail: string,
): Promise<ApiResponse<boolean>> => {
  return request<ApiResponse<boolean>>({
    url: `project/${projectId}/remove-invitees/${userEmail}`,
    method: "DELETE",
  });
};

export const getAllFeedback = async (
  projectId: string,
  expand?: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${projectId}/get-all`,
    method: "POST",
    data: { expand },
  });
};

export const getAllFeedbackExpand = async (
  projectId: string,
  expand?: boolean,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${projectId}/get-all`,
    method: "POST",
    data: { expand },
  });
};

export const requestFeedback = async (
  projectId: string,
  message: string,
): Promise<ApiResponse<TRequestFeedback>> => {
  return request<ApiResponse<TRequestFeedback>>({
    url: `project/${projectId}/request-feedback`,
    method: "POST",
    data: { message },
  });
};

export const provideFeedback = async (
  requestId: string,
  feedback: string,
  adminComment?: string,
): Promise<ApiResponse<TProvideFeedback>> => {
  return request<ApiResponse<TProvideFeedback>>({
    url: `comment/${requestId}/provide-feedback`,
    method: "POST",
    data: { message: feedback, adminComment },
  });
};

export const editFeedback = async (
  submissionId: string,
  submissionMessage: string,
): Promise<ApiResponse<TProvideFeedback>> => {
  return request<ApiResponse<TProvideFeedback>>({
    url: `comment/edit-feedback`,
    method: "POST",
    data: { submissionId, submissionMessage },
  });
};

export const getRequestedFeedback = async (): Promise<
  ApiResponseList<TFeedback>
> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/getAll`,
    method: "GET",
  });
};

export const getRequestedFeedbackForAdmin = async (
  customSearchSort?: any,
  skip = 0,
  limit = 2,
): Promise<ApiResponse<TList<TFeedback>>> => {
  return request<ApiResponse<TList<TFeedback>>>({
    url: `comment/getFeedbackRequests`,
    method: "GET",
    params: {
      limit,
      skip,
      ...customSearchSort,
    },
  });
};

export const lockFeedback = async (
  requestedFeedbackId: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${requestedFeedbackId}/lock-comment`,
    method: "POST",
  });
};

export const removeFeedback = async (
  requestedFeedbackId: string,
  adminComment?: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${requestedFeedbackId}/remove-comment`,
    method: "POST",
    data: { adminComment },
  });
};

export const addTagComment = async (
  requestedFeedbackId: string,
  adminComment: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${requestedFeedbackId}/add-admin-comment`,
    method: "POST",
    data: { adminComment },
  });
};

export const moveBacktoQueue = async (
  requestedFeedbackId: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${requestedFeedbackId}/move-back-to-queue`,
    method: "PATCH",
  });
};

export const cancelFeedbackReply = async (
  requestedFeedbackId: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `comment/${requestedFeedbackId}/cancel-reply`,
    method: "PATCH",
  });
};

export const submitProject = async (
  projectId: string,
  data: TProjectSubmission,
): Promise<ApiResponseList<TFeedback>> => {
  localStorage.setItem("userChange", "true");
  return request<ApiResponseList<TFeedback>>({
    url: `project/${projectId}/submit-work`,
    method: "POST",
    data,
  });
};

export const reSubmitProject = async (
  projectId: string,
  data: TProjectSubmission,
): Promise<ApiResponseList<TFeedback>> => {
  localStorage.setItem("userChange", "true");
  return request<ApiResponseList<TFeedback>>({
    url: `project/${projectId}/submit-work`,
    method: "PATCH",
    data,
  });
};

export const updateSubmissionUrl = async (
  projectId: string,
  url: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `project/${projectId}/submission-url`,
    method: "PATCH",
    data: {
      submission_url: url,
    },
  });
};

export const updateSubmissionComment = async (
  projectId: string,
  comment: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `project/${projectId}/submission-comment`,
    method: "PATCH",
    data: {
      comment,
    },
  });
};

export const getUserDetailOnProject = async (
  projectId: string,
): Promise<ApiResponse<TProject>> => {
  return request<ApiResponse<TProject>>({
    url: `project/${projectId}`,
    method: "GET",
  });
};

export const giveScoreForProject = async (
  userId: string,
  subjectType: "project" | "skillbuilder",
  subjectId: string,
  type: "system" | "admin",
  score: number,
): Promise<ApiResponse<TScoring>> => {
  return request<ApiResponse<TScoring>>({
    url: `transaction/add-system-score`,
    method: "POST",
    data: {
      user_id: userId,
      type,
      subject_type: subjectType,
      subject_id: subjectId,
      score,
    },
  });
};

export const getProjectsList = async (
  customSearchSort?: any,
  skip = 0,
  limit = 2,
): Promise<ApiResponse<TList<TProject>>> => {
  return request<ApiResponse<TList<TProject>>>({
    url: `/projects`,
    params: {
      limit,
      skip,
      ...customSearchSort,
    },
  });
};

export const getUsersProjectsList = async (
  customSearchSort?: any,
  skip = 0,
  limit = 2,
): Promise<ApiResponse<TList<TProject>>> => {
  return request<ApiResponse<TList<TProject>>>({
    url: `/user-projects`,
    params: {
      limit,
      skip,
      ...customSearchSort,
    },
  });
};

export const deleteComment = async (
  id: string,
): Promise<ApiResponseList<TFeedback>> => {
  return request<ApiResponseList<TFeedback>>({
    url: `/comment/${id}`,
    method: "DELETE",
  });
};

export const getComment = async (
  id: string,
): Promise<ApiResponse<TFeedback>> => {
  return request<ApiResponse<TFeedback>>({
    url: `/comment/${id}`,
    method: "GET",
  });
};

export const generateProjectCsv = async (filters: any) => {
  const response = await request<BlobPart>({
    url: `/project/get-csv`,
    responseType: "blob",
    params: filters,
  });
  return new Blob([response]);
};

export const generateUserProjectCsv = async (filters: any) => {
  const response = await request<BlobPart>({
    url: `/project/get-user-csv`,
    responseType: "blob",
    params: filters,
  });
  return new Blob([response]);
};

export const declineProjectInvite = async (
  inviterId: string,
): Promise<ApiResponse<TProject>> => {
  return request<ApiResponse<TProject>>({
    url: `project/declineProjectInvite/${inviterId}`,
    method: "GET",
  });
};

export const getSurveySummary = async (
  challengeId?: string,
): Promise<ApiResponse<TSurveySummary>> => {
  return request<ApiResponse<TSurveySummary>>({
    url: `survey-results-summary`,
    method: "GET",
    params: {
      challengeId,
    },
  });
};

export const generateSurveyCsv = async (challengeId?: string) => {
  const response = await request<BlobPart>({
    url: `survey-csv`,
    responseType: "blob",
    params: {
      challengeId,
    },
  });
  return new Blob([response]);
};

export const changeWinnerStatus = async (
  id: string,
  winner: number | undefined | null,
  winnerType: boolean,
) => {
  return request<ApiResponse<TProject>>({
    url: `update_project_winner`,
    method: "POST",
    data: { id, winner, winnerType },
  });
};
