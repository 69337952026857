/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import Link from "next/link";
import { DefaultPage, IInternalLinkProps } from "../../models/Contentful/types";
import SvgLib from "../SvgLib";
import { AvailableIcons } from "../SvgLib/@types";

interface CIntealLinkProps extends IInternalLinkProps {
  classes?: string;
  showPrivateLinks?: boolean;
  children?: React.ReactNode;
  iconName?: AvailableIcons;
  iconClass?: string;
  onClick?: () => void;
  ariaLabel?: string;
}

const InternalLink: FC<CIntealLinkProps> = ({
  text,
  referencePage,
  layout,
  classes = "",
  children,
  showPrivateLinks = true,
  iconName,
  iconClass,
  scrollPage = true,
  onClick,
  ariaLabel,
}) => {
  const { fields: page } = referencePage;
  const layoutClass =
    layout === "Primary"
      ? "is-primary"
      : layout === "Secondary"
        ? "is-secondary"
        : layout === "Text"
          ? "is-text"
          : "";

  if (showPrivateLinks || !page.isPrivate)
    return (
      <Link
        scroll={scrollPage}
        href={`/${page.slug}`}
        passHref
        className={`${classes} ${layoutClass} is-family-secondary`}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {children ??
          text ??
          (page as unknown as DefaultPage).title ??
          page.slug}
        {iconName && (
          <SvgLib
            icon={iconName}
            classes={`is-inline ${iconClass} ${classes}`}
          />
        )}
      </Link>
    );
  return null;
};

export default InternalLink;
