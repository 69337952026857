import { FC } from "react";
import Head from "next/head";
import { CSeoTemplate } from "../models/Contentful/types";
import { SeoMetaDataGraphQL } from "../models/Contentful/types/siteTemplateGraphQL";

export interface IMetaHead {
  siteTitle?: string;
  pageTitle?: string;
  seoMetadata?: SeoMetaDataGraphQL;
  pageSeoMetadata?: CSeoTemplate;
}

const MetaHead: FC<IMetaHead> = ({
  siteTitle,
  pageTitle,
  seoMetadata = {} as SeoMetaDataGraphQL,
  pageSeoMetadata = {} as CSeoTemplate,
}) => {
  const { image: seoMetaImage, ...seoMetaDataRest } = seoMetadata || {};
  const { image: pageImageUrl, ...pageSeoDataRest } =
    pageSeoMetadata?.fields || {};
  const seoData = {
    ...seoMetaDataRest,
    ...pageSeoDataRest,
    image: {
      url:
        (pageImageUrl?.fields?.file?.url &&
          `https:${pageImageUrl?.fields?.file?.url}`) ||
        seoMetaImage?.url,
      height: (
        pageImageUrl?.fields?.file?.details?.image?.height ||
        seoMetaImage?.height
      )?.toString(),
      width: (
        pageImageUrl?.fields?.file?.details?.image?.width || seoMetaImage?.width
      )?.toString(),
    },
  };

  const seoKeywords = seoData?.keywords ?? [];
  const title = [seoData.title ?? pageTitle, siteTitle]
    .filter((item) => !!item)
    .join(" | ");
  const robotsField = [
    seoData.noIndex ? "noindex" : undefined,
    seoData.noFollow ? "nofollow" : undefined,
  ].filter((item) => !!item);
  return (
    <Head>
      {siteTitle || pageTitle ? <title>{title}</title> : null}

      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=5"
      />

      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      {seoData.description ? (
        <>
          <meta name="description" content={seoData.description} />
          <meta property="og:description" content={seoData.description} />
          <meta name="twitter:description" content={seoData.description} />
        </>
      ) : null}

      {seoData.type ? (
        <meta property="og:type" content={seoData?.type} />
      ) : null}

      {seoData.site ? (
        <meta property="twitter:site" content={seoData?.site} />
      ) : null}

      {seoData?.siteName && (
        <meta name="og:site_name" content={seoData?.siteName} />
      )}

      {seoData.card ? (
        <meta property="twitter:card" content={seoData?.card} />
      ) : null}

      {seoData.creator ? (
        <meta property="twitter:creator" content={seoData?.creator} />
      ) : null}

      {seoData.image?.url ? (
        <>
          <meta property="og:image" content={seoData?.image?.url} />
          <meta property="twitter:image" content={seoData?.image?.url} />
        </>
      ) : null}

      {seoData.image?.height ? (
        <meta property="og:image:height" content={seoData?.image?.height} />
      ) : null}

      {seoData?.image?.width ? (
        <meta property="og:image:width" content={seoData?.image?.width} />
      ) : null}

      {robotsField.length ? (
        <meta name="robots" content={robotsField.join(",")} />
      ) : null}

      {seoKeywords?.length && (
        <meta name="keywords" content={seoKeywords.join(", ")} />
      )}

      {seoData?.canonicalUrl ? (
        <link rel="canonical" href={seoData.canonicalUrl} />
      ) : null}

      {seoData?.url ? <meta property="og:url" content={seoData?.url} /> : null}

      <link rel="shortcut icon" href="/favicon.ico" />
      <link
        rel="icon"
        sizes="16x16"
        href="/static/images/favicon/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        sizes="192x192"
        href="/static/images/favicon/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/static/images/favicon/android-chrome-512x512.png"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="16x16"
        href="/static/images/favicon/apple-touch-icon.png"
      />
    </Head>
  );
};

export default MetaHead;
