import { ParsedUrlQuery } from "querystring";
import dayjs, { Dayjs } from "dayjs";
import * as Yup from "yup";
import contentfulClientGraphQLI from "models/ContentfulGraphQLi";
import {
  ChallengeFilterOutInternals,
  IChallengeTab,
  IChallengeWidgetItems,
  ITabItemsCollection,
} from "../models/Challenge/@types";
import { IWinnerTab } from "../models/Winners/@types";
import { TabsBladeProps } from "../blades/tabs/@types";
import { CLinkProps, CType } from "../models/Contentful/types";
import { DeepLinkingEvent, Events } from "./Events/@types";

export const DEFAULT_DATE_FORMAT = "MM/DD/YY";
export const DATE_TIME_FORMAT = "MM/DD/YY HH:mm z";
export const DEFAULT_TIMEZONE = "America/New_York";

export const getTabLink = (
  tab: IChallengeTab | IWinnerTab,
  baseUrl: string,
  entryId: string,
): CLinkProps => {
  return {
    sys: { contentType: { sys: { id: "internalLinks", type: "Link" } } },
    fields: {
      text: tab.name,
      scrollPage: false,
      referencePage: {
        sys: { contentType: { sys: { id: "page", type: "Entry" } } },
        fields: {
          slug: `${baseUrl}/${tab.slug}`,
          entryId,
          directory: "",
        },
      },
    },
  };
};

export const getTabsWidget = (widgets: CType<unknown>[] = []) => {
  return widgets.find(
    (widget) => widget.sys?.contentType?.sys?.id === "tabsBlade",
  ) as CType<TabsBladeProps>;
};

export const getTabSlugs = (
  widgets: CType<unknown>[] = [],
): string[] | undefined => {
  const slugs: string[] = [];
  const tabs = getTabsWidget(widgets);
  if (!tabs) return undefined;
  const { tabItems = [] } = tabs.fields;
  tabItems.forEach((tabItem) => {
    if (tabItem.fields.slug) slugs.push(tabItem.fields.slug);
  });
  if (slugs.length) return slugs;
  return undefined;
};

export const getTabsWidgetGraphQL = (widgets: IChallengeWidgetItems[] = []) => {
  // eslint-disable-next-line no-underscore-dangle
  return widgets.find((widget) => widget?.__typename === "TabsBlade");
};

export const getTabSlugsGraphQL = (
  widgets: IChallengeWidgetItems[] = [],
): string[] | undefined => {
  const slugs: string[] = [];
  const tabs = getTabsWidgetGraphQL(widgets);
  if (!tabs) return undefined;
  const { items } = tabs.tabItemsCollection as { items: ITabItemsCollection[] };
  items.forEach(({ slug }) => {
    if (slug) slugs.push(slug);
  });
  if (slugs.length) return slugs;
  return undefined;
};

export const getUrlFromQuery = (query: ParsedUrlQuery) => {
  if (!query.slug) return "";
  if (typeof query.slug === "string") return query.slug;
  return (query.slug as Array<string>).join("/");
};

export const getChallengesForSelect = async () => {
  try {
    const {
      challengeCollection: { items },
    } = await contentfulClientGraphQLI({
      query: ChallengeFilterOutInternals,
    });

    const challengesOptions = items.map((item: any) => ({
      id: item.slug,
      name: item.title,
      value: item.sys.id,
    }));

    return challengesOptions;
  } catch {
    return [];
  }
};

const helpers = {
  isEmail: (email: string) =>
    !!Yup.string().email().isValidSync(email.toLowerCase()),
  generatePath: (path: string, params: Record<string, string>): string =>
    path.replace(/{([^{}]+)}/g, (keyExpr, key) => params[key] || ""),
  date: (date: string): Dayjs => {
    // return dayjs(date).tz(DEFAULT_TIMEZONE);
    return dayjs(date).tz(dayjs.tz.guess());
  },
  dateString: (date: string, format = DEFAULT_DATE_FORMAT) => {
    return helpers.date(date).format(format);
  },
  placeMaker: (place: number) => {
    switch (place) {
      case 1:
        return "1st Place";
      case 2:
        return "2nd Place";
      case 3:
        return "3rd Place";
      case 4:
        return "Runner-up";
      default:
        return "";
    }
  },
  slugStringify: (str: string) => {
    const slug = str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
    return slug;
  },
  eventEmitor: (eve?: Events | DeepLinkingEvent) => {
    if (!eve) return;
    const event = new CustomEvent(eve, {});

    document?.dispatchEvent(event);
  },
};

export default helpers;
