// eslint-disable-next-line import/no-cycle
import React, { FC } from "react";
import { IGetInvolvedFormProps } from "./@types";
import Form from "./Form";
import Header from "./Header";

const BecomeMentor: FC<IGetInvolvedFormProps> = ({ onSubmit }) => {
  return (
    <div>
      <Header
        title="Become a Mentor"
        description="We’re so glad you’re interested in joining the ranks of dedicated mentors at KnoPro! To apply, simply submit the form below. You don’t have to have a specific title or experience, but all KnoPro mentors must be actively affiliated with an organization or have an employer email."
      />
      <Form
        onSubmit={onSubmit}
        isCommentOptional
        formData={{
          comment: {
            label: "Submission Box: (optional)",
            placeHolder: "Add your comments",
          },
        }}
      />
    </div>
  );
};

export default BecomeMentor;
