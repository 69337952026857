import { Avatars } from "../Screens/Dashboard/Sections/Header/avatar";

export const KNO_PRO_SUPPORT_EMAIL = "support@knopro.org";

export const PASSWORD_VALIDATION_REGEXP = /^.{6,}$/;
// /^(?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*\d){1,}(?=.*[`~!@#$%^&*+=:;"'<,>.?\_\(\{\}\)\[\]\|\\\/\-]){1,}(?=.{6,})/;

export const DEFAULT_COUNTRY = "US";

export enum IS_USER_AUTHENTICATED {
  YES = "yes",
  NO = "no",
}

export const OVERVIEW_TAB = "overview";

export const STATIC_IMAGE_PATH = "/static/images/";
export const DEFAULT_AVATAR = Avatars[4];
export const AVATAR_IMAGE_PATH = `${STATIC_IMAGE_PATH}avatar/`;
export const AVATAR_SIZES = {
  small: 43,
};
export const LINE_ICON_PATH = `${STATIC_IMAGE_PATH}1.3.0/css/line-awesome.min.css`;

export const PROJECT_LEFT_SECTION_ID = "project-page-left-section";
export const PROJECT_RIGHT_SECTION_ID = "project-page-right-section";
export const PROJECT_BANNER_ID = "project-page-right-section-banner";
export const PROJECT_REQUEST_FEEDBACK_ID =
  "project-page-right-section-request-feedback-form-id";

export const STORAGE_TOKEN_KEY = "inviteToken";
export const STORAGE_PROJECT_ID = "projectId";

export const COOKIE_INFO = "isCookieAccepted";

export const STORAGE_UTM_PARAMS = "utmParams";

export const DEEPLINK_CHALLENGE_ID = "deepLinkChallengeId";

const DASHBOARD_PATH = "/dashboard";
const PROJECT_PATH = `${DASHBOARD_PATH}/project`;
const SUBMIT_PROJECT_PATH = `submit`;
const RE_SUBMIT_PROJECT_PATH = `re-submit`;
export const ROUTES = {
  DASHBOARD: DASHBOARD_PATH,
  CLASSROOM_DASHBOARD: `${DASHBOARD_PATH}/classrooms/`,
  DASHBOARD_SETTINGS: `${DASHBOARD_PATH}/settings`,
  PROJECT: `${PROJECT_PATH}?id={projectId}`,
  SUBMIT: `${PROJECT_PATH}/${SUBMIT_PROJECT_PATH}?id={projectId}`,
  RE_SUBMIT: `${PROJECT_PATH}/${RE_SUBMIT_PROJECT_PATH}?id={projectId}`,
  PROJECT_INVITE: `${PROJECT_PATH}/invite?id={projectId}`,
  LOGIN: `/log-in`,
  SIGNUP: `/sign-up`,
  RESET_PASSWORD_LOGIN: `/reset-log-in`,
  HOME: "/",
  CONTACT_US: "/contact-us",
  CHALLENGE: "/challenges/{slug}/overview",
  SKILLBUILDER: "/skillbuilders/slug",
  MANAGE_USER_DASHBOARD: "/manage/users/",
  MANAGE_FEEDBACK: "/manage/feedback-requests/",
  MANAGE_PROJECT: "/manage/projects/",
  MANAGE_SKILLBUILDER: "/manage/skillbuilders/",
  FEEDBACK_REQUEST: "/manage/feedback-request/?id={feedbackRequestId}",
  FAQ: "/faq",
  // JOIN_CLASSROOM: `${DASHBOARD_PATH}/classroom/?id={classroomId}&tab=teams`,
  JOIN_CLASSROOM: `${DASHBOARD_PATH}/classrooms/?tab=classes`,
  SURVEY_RESULTS: "/manage/survey-results",
};

export const CardsTypes = {
  PARTNER: "Partner",
  WINNER: "Winner",
  CHALLENGE: "Challenge",
  IMAGE: "Image",
  SKILLBUILDER: "Skillbuilder",
};

export const TypeContentfulMap = {
  [CardsTypes.PARTNER]: "partnerCard",
  [CardsTypes.WINNER]: "winners",
  [CardsTypes.CHALLENGE]: "challenge",
  [CardsTypes.IMAGE]: "mediaCards",
  [CardsTypes.SKILLBUILDER]: "skillbuilder",
};

export const LogInResponseCode = {
  TWO_FACTOR_AUTH_REQUIRED: "TWO_FACTOR_AUTH_REQUIRED",
};

export const UtmParam = {
  SOURCE: "utm_source",
  MEDIUM: "utm_medium",
  CAMPAIGN: "utm_campaign",
};

export const FORMATS = [
  "bold",
  "italic",
  "underline",
  "list",
  "ordered",
  "bullet",
  "link",
];

export const MODULES = {
  toolbar: [
    ["bold"],
    ["italic"],
    ["underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
  clipboard: { matchVisual: false },
};
