import React, { FC } from "react";
import clsx from "clsx";

import SvgLib from "../SvgLib";
import styles from "./modalwrapper.module.sass";

export interface ModalProps {
  isActive: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  hasCloseButton?: boolean;
  closeBtnClasses?: string;
}

const ModalWrapper: FC<ModalProps> = (props) => {
  const {
    isActive,
    onClose,
    children,
    hasCloseButton = false,
    closeBtnClasses,
  } = props;
  return (
    <div className={clsx("modal", { "is-active": isActive })}>
      <div className="modal-background" onClick={onClose} aria-hidden="true" />
      <div
        className={clsx("modal-content", {
          [styles.content]: true,
        })}
      >
        {hasCloseButton && (
          <button
            type="button"
            aria-label="close"
            onClick={onClose}
            className={closeBtnClasses}
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              border: "none",
              background: "none",
              padding: "unset",
              cursor: "pointer",
              zIndex: 2100,
            }}
          >
            <SvgLib icon="cross" />
          </button>
        )}

        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
