/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
// /* eslint-disable react/button-has-type */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import NextLink from "next/link";
import React, { FC } from "react";
import { CLinkProps } from "../../models/Contentful/types";
import Link from "../Link";
import SvgLib from "../SvgLib";
import { AvailableIcons, ISvgLib } from "../SvgLib/@types";

export type ThemeTypeDefault =
  | "default"
  | "main"
  | "primaryDefault"
  | "primaryMain"
  | "secondaryDefault"
  | "secondaryMain"
  | "secondaryGrad";

export type ThemeType =
  | "Filled - Lime"
  | "Filled - Green"
  | "Filled - Lime Green Gradient"
  | "Transparent - Black Border"
  | "Transparent - Blue Border"
  | "Transparent - White Border"
  | "Transparent - Green Border";

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isBtn?: boolean;
  underline?: boolean;
  icon?: AvailableIcons;
  iconPos?: "top" | "bottom" | "left" | "right";
  iconClass?: string;
  iconSize?: ISvgLib["iconSize"];
  eventLink?: boolean;
  onlyText?: boolean;
  isLoader?: boolean;
  classes?: string;
  theme?: ThemeType;
  text?: boolean;
  small?: boolean;
  medium?: boolean;
  normal?: boolean;
  large?: boolean;
  heightLarge?: boolean;
  link?: CLinkProps;
  linkToUrl?: string;
  isExternalLink?: boolean;
  openInSameTab?: boolean;
  openInNewTab?: boolean;
  dark?: boolean;
  showPrivateLinks?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
}
const Button: FC<IButtonProps> = (props) => {
  const {
    type = "button",
    underline,
    icon,
    iconClass,
    iconPos,
    iconSize,
    eventLink,
    onlyText,
    isLoader,
    classes = "",
    theme = "",
    text,
    small,
    medium,
    normal,
    large,
    heightLarge,
    dark,
    children,
    isBtn = true,
    isExternalLink,
    openInSameTab,
    link,
    linkToUrl,
    showPrivateLinks,
    openInNewTab,
    ariaLabel,
    ...rest
  } = props;

  let themeType = "";
  switch (theme) {
    case "Transparent - Blue Border":
      themeType = "is-main";
      break;
    case "Transparent - White Border":
      themeType = "is-primary-default";
      break;
    case "Transparent - Green Border":
      themeType = "is-primary-main";
      break;
    case "Filled - Lime":
      themeType = "is-secondary-default";
      break;
    case "Filled - Green":
      themeType = "is-secondary-main";
      break;
    case "Filled - Lime Green Gradient":
      themeType = "is-secondary-grad";
      break;
    default:
      themeType = "";
  }

  const cls = clsx(
    isBtn ? "button is-responsive is-align-self-center" : "is-inline-block",
    underline ? "is-underlined" : "",
    classes,
    themeType,
    text ? "is-text" : "",
    small ? "is-small" : "",
    medium ? "is-medium" : "",
    normal ? "is-normal" : "",
    large ? "is-large" : "",
    heightLarge ? "is-height-large" : "",
    eventLink ? "is-eventLink" : "",
    onlyText ? "is-onlyText" : "",
    dark ? "is-btn-dark" : "",
    dark && text ? "has-text-white" : "",
    isLoader ? "is-loader" : ""
  );
  if (link) {
    return (
      <Link
        {...link}
        ariaLabel={ariaLabel}
        classes={cls}
        showPrivateLinks={showPrivateLinks}
        icon={icon}
        iconClass={iconClass}
        onClick={rest.onClick}
      />
    );
  }

  if (linkToUrl) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <NextLink
        aria-label={ariaLabel}
        href={
          isExternalLink
            ? linkToUrl
            : `${linkToUrl.startsWith("/") ? "" : "/"}${linkToUrl}`
        }
        passHref
        onClick={rest.onClick}
        className={cls}
        target={
          isExternalLink
            ? openInSameTab
              ? "_self"
              : "_blank"
            : openInNewTab
            ? "_blank"
            : undefined
        }
        rel={isExternalLink ? "noreferrer" : undefined}
      >
        {children}
      </NextLink>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={cls} aria-label={ariaLabel} {...rest}>
      {children}
      {icon && (
        <SvgLib
          icon={icon}
          classes={iconClass}
          iconPos={iconPos}
          iconSize={iconSize}
        />
      )}
    </button>
  );
};

export default Button;
