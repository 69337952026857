/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { FC } from "react";
import clsx from "clsx";

import { AvailableIcons, IIconsLibrary, ISvgLib } from "./@types";
import Icons from "./icons";
import { sanitize } from "../../helpers";
import styles from "./icons.module.sass";

//  color
const SvgLib: FC<ISvgLib> = ({ icon, classes, iconPos, iconSize, color }) => {
  let name;

  if (typeof icon === "string") {
    name = icon;
  } else {
    name = icon.fields.name;
  }
  let iconCls = "";
  switch (iconPos) {
    case "top":
      iconCls = "is-top";
      break;
    case "right":
      iconCls = "is-right";
      break;
    case "bottom":
      iconCls = "is-bottom";
      break;
    case "left":
      iconCls = "is-left";
      break;
    default:
      break;
  }
  const sanitizedName: AvailableIcons = sanitize(name) as AvailableIcons;
  const iconsLibrary: IIconsLibrary = Icons({ color });

  const cls = clsx(
    classes,
    "icon",
    styles.icons,
    styles[iconCls],
    iconSize === "fullWidth"
      ? styles.fullWidth
      : iconSize === "Large"
        ? styles.largeIcon
        : iconSize === "Medium" && styles.mediumIcon,
  );

  return <span className={cls}>{iconsLibrary[sanitizedName]}</span>;
};

export default SvgLib;
