/* eslint-disable prettier/prettier */
import React, { FC } from "react";
import Image from "next/image";
import clsx from "clsx";

import { CMedia } from "../../models/Contentful/types";
import utils from "../../utils";
import styles from "./sectionWrapper.module.sass";

export interface SectionWrapperProps {
  hasBodyMargin?: boolean;
  backgroundImage?: CMedia;
  backgroundColor?: string;
  parentBackgroundColor?: string;
  hasFullHeight?: boolean;
  hasImageBlur?: boolean;
  classes?: string;
  childClasses?: string;
  isDark?: boolean;
  children?: React.ReactNode;
}

const SectionWrapper: FC<SectionWrapperProps> = (props) => {
  const {
    children,
    hasBodyMargin = true,
    backgroundImage,
    parentBackgroundColor,
    hasFullHeight = false,
    hasImageBlur = false,
    classes,
    childClasses,
    isDark = false,
  } = props;

  const bodyCls = clsx(
    styles.sectionWrapper,
    hasFullHeight ? styles.fullHeight : "",
    isDark ? styles.darkTheme : "",
    classes
  );

  const childCls = clsx(
    hasBodyMargin ? `container` : "",
    hasFullHeight ? styles.fullHeight : "",
    styles.sectionChild,
    styles.richTextPadding,
    childClasses,
    { [`is-bgcolor-${parentBackgroundColor}`]: !!parentBackgroundColor }
  );

  return (
    <div className={`${bodyCls}`}>
      {backgroundImage?.fields?.file.url && (
        <>
          <Image
            loader={utils.imageLoader}
            src={backgroundImage.fields.file.url}
            className={styles.sectionBgImg}
            alt="pic"
            fill
            style={{
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
          {hasImageBlur && <div className={styles.imageBlur} />}
        </>
      )}
      <div className={childCls}>{children}</div>
    </div>
  );
};

export default SectionWrapper;
