/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { FC } from "react";
import Link from "next/link";
import { IInternalLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";
import SvgLib from "../SvgLib";
import { AvailableIcons } from "../SvgLib/@types";

interface CInternalLinkProps {
  classes?: string;
  showPrivateLinks?: boolean;
  children?: React.ReactNode;
  iconName?: AvailableIcons;
  iconClass?: string;
  scrollPage?: boolean;
}

const InternalLinkGraphQL: FC<
  CInternalLinkProps & IInternalLinkPropsGraphQL
> = ({
  text,
  referencePage,
  layout,
  classes = "",
  children,
  showPrivateLinks = true,
  iconName,
  iconClass,
  scrollPage = true,
}) => {
  const { slug, title } = referencePage;
  const layoutClass =
    layout === "Primary"
      ? "is-primary"
      : layout === "Secondary"
        ? "is-secondary"
        : layout === "Text"
          ? "is-text"
          : "";

  if (showPrivateLinks)
    return (
      <Link
        scroll={scrollPage}
        href={`/${slug}`}
        passHref
        className={`${classes} ${layoutClass} is-family-secondary`}
      >
        {children ?? text ?? title ?? slug}
        {iconName && (
          <SvgLib
            icon={iconName}
            classes={`is-inline ${iconClass} ${classes}`}
          />
        )}
      </Link>
    );
  return null;
};

export default InternalLinkGraphQL;
