/* eslint-disable import/no-cycle */
/* eslint-disable default-param-last */
import { ApiResponse, ApiResponseList, TList } from "../@types";
import User, { TAvatar } from "../auth/@types";
import { request } from "../../utils";
import {
  TChallengesInfoForUser,
  TProjectInvites,
  TProject,
  TScoring,
  TSkillbuilderInfoForUser,
} from "./@types";

export const getActiveProjects = async (): Promise<
  ApiResponseList<TProject>
> => {
  return request<ApiResponseList<TProject>>({
    url: `users/projects?type=active`,
    method: "GET",
  });
};

export const getUserActiveProjects = async (
  userId: string,
): Promise<ApiResponseList<TProject>> => {
  return request<ApiResponseList<TProject>>({
    url: `users/${userId}/projects?type=active`,
    method: "GET",
  });
};

export const hasFeedbackNotification = async (): Promise<
  ApiResponse<boolean>
> => {
  return request<ApiResponse<boolean>>({
    url: `users/hasFeedbackNotification`,
    method: "GET",
  });
  // const { data } = await result;
  // utils.setCookie("hasFeedbackNotification", data ? "yes" : "no");
  // return data;
};

export const totalCurrentScore = async (
  userId: string,
): Promise<ApiResponse<number>> => {
  return request<ApiResponse<number>>({
    url: `transaction/${userId}/total-score`,
    method: "GET",
  });
};

export const getCompletedProjects = async (): Promise<
  ApiResponseList<TProject>
> => {
  return request<ApiResponseList<TProject>>({
    url: `users/projects?type=completed`,
    method: "GET",
  });
};

export const getUserCompletedProjects = async (
  userId: string,
): Promise<ApiResponseList<TProject>> => {
  return request<ApiResponseList<TProject>>({
    url: `users/${userId}/projects?type=completed`,
    method: "GET",
  });
};

export const updateUser = async (
  id: string,
  data: Partial<User>,
): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: `users/${id}`,
    method: "PATCH",
    data: { ...data, city: data.city === undefined ? null : data.city },
  });
};

export const updateAvatar = async (
  id: string,
  avatar: TAvatar,
): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: `users/add-avatar/${id}`,
    method: "PATCH",
    data: { avatar },
  });
};

export const giveSystemScore = async (
  userId: string,
  subjectType: "project" | "skillbuilder",
  subjectId: string,
  type: "system",
  score: number,
): Promise<ApiResponse<TScoring>> => {
  return request<ApiResponse<TScoring>>({
    url: `transaction/add-system-score`,
    method: "POST",
    data: {
      user_id: userId,
      type,
      subject_type: subjectType,
      subject_id: subjectId,
      score,
    },
  });
};

export const getChallengesInfoForUser = async (
  challengeIds: string[],
): Promise<ApiResponse<TChallengesInfoForUser[]>> => {
  return request<ApiResponse<TChallengesInfoForUser[]>>({
    url: `users/challenge-details`,
    method: "POST",
    data: { challengeIds },
  });
};
// Gets Skillbuilder template information e.g. title and status information
export const getSkillbuilderInfoForUser = async (
  skillbuilderIds: string[],
): Promise<ApiResponse<TSkillbuilderInfoForUser[]>> => {
  return request<ApiResponse<TSkillbuilderInfoForUser[]>>({
    url: `users/skillbuilder-details`,
    method: "POST",
    data: { skillbuilderIds },
  });
};

// Gets Skillbuilder template information e.g. id, status, submission
export const getSkillbuilderInfoForStudent = async (
  userId: string,
): Promise<ApiResponse<any[]>> => {
  return request<ApiResponse<any[]>>({
    url: `users/skillbuilder-student`,
    method: "POST",
    data: { userId },
  });
};

export const getUsersList = async (
  skip = 0,
  limit = 2,
  customSearchSort?: any,
): Promise<ApiResponse<TList<User>>> => {
  return request<ApiResponse<TList<User>>>({
    url: `/users`,
    params: {
      limit,
      skip,
      ...customSearchSort,
    },
  });
};

export const generateUserCsv = async () => {
  const response = await request<BlobPart>({
    url: `/users/get-csv`,
    responseType: "blob",
  });
  return new Blob([response]);
};

export const getUser = async (userId: string): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: `/users/${userId}`,
  });
};

export const blockUser = async (email: string): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: `/users/block?email=`,
    method: "POST",
    data: { email },
  });
};

export const unBlockUser = async (
  email: string,
): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: `/users/unblock?email=`,
    method: "POST",
    data: { email },
  });
};

export const getUserInvites = async (): Promise<
  ApiResponse<TProjectInvites[]>
> => {
  return request<ApiResponse<TProjectInvites[]>>({
    url: `/users/invites`,
    method: "GET",
  });
};

export const getUserInvitesForProject = async (
  email: string,
): Promise<ApiResponse<TProjectInvites[]>> => {
  return request<ApiResponse<TProjectInvites[]>>({
    url: `/users/${email}/invites`,
    method: "GET",
  });
};
