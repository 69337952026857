/* eslint-disable no-nested-ternary */
import React, { FC, useState } from "react";
import Image from "next/image";

import { CMedia } from "../../models/Contentful/types";
import styles from "./image.module.sass";
import utils from "../../utils";

export interface IImageComponentProps {
  classes?: string;
  image: CMedia | string | undefined;
  fallback?: string;
  aspectRatio?: number | string;
  height?: number | string;
  width?: number | string;
  crop?: boolean;
  hasBackdrop?: boolean;
  children?: React.ReactNode;
}

const fallbackUrl =
  "https://images.unsplash.com/photo-1449156733864-dd5471bb7427?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";
const getImage = (
  image:
    | CMedia
    | string
    | undefined
    | { src: string; height: number; width: number },
  fallback: string | undefined,
) => {
  let url: string | undefined;
  let alt = "image";
  if (typeof image === "string") {
    if (image.includes("logo")) {
      alt = "KnoPro Logo";
    } else {
      const imageSplit = image.split("/");
      const imageName = imageSplit[imageSplit.length - 1];
      alt = imageName?.split(".")[0];
    }
  }
  if (typeof image === "string") {
    url = image;
  } else if (typeof image === "object" && (image as any)?.src) {
    url = (image as any).src;
  } else {
    url =
      image && (image as any)?.fields?.file?.url
        ? (image as any)?.fields?.file?.url
        : fallback;
    alt =
      image && (image as any)?.fields?.title
        ? (image as any)?.fields?.title
        : alt;
  }
  return { url, alt };
};

const ImageComponent: FC<IImageComponentProps> = (props) => {
  const {
    classes = "",
    image,
    aspectRatio,
    height,
    width,
    crop = false,
    children,
    fallback,
    hasBackdrop = false,
  } = props;
  const [imageContent, setImageContent] = useState(getImage(image, fallback));
  const aspectRatioNum =
    typeof aspectRatio === "string"
      ? parseInt(aspectRatio.split("/")[0], 10) /
        parseInt(aspectRatio.split("/")[1], 10)
      : aspectRatio;

  if (imageContent.url)
    return (
      <div
        className={`${styles.image} ${classes} imageComponent ${
          hasBackdrop ? styles.bgOpaque : ""
        } is-flex is-justify-content-center image ${
          aspectRatio && aspectRatioNum
            ? aspectRatioNum <= 1
              ? `${styles.fitHeight}`
              : ""
            : ""
        } `}
        style={
          aspectRatio
            ? { aspectRatio: `${aspectRatio}`, height, width }
            : { height, width }
        }
      >
        <Image
          loader={utils.imageLoader}
          src={imageContent.url}
          alt={imageContent.alt}
          fill
          style={{
            objectFit: `${
              aspectRatio ? (crop ? "cover" : "unset") : "contain"
            }`,
          }}
          className={styles.imageComponent}
          onError={() => {
            setImageContent(getImage(fallback, fallbackUrl));
          }}
        />

        <div className={styles.imageChild}>{children}</div>
      </div>
    );
  return null;
};

export default ImageComponent;
