/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-cycle */

import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import React, { FC } from "react";

import Button from "../Button";
import IFrameContainer from "../IFrameContainer";
import Image from "../Image";
import Typo from "../Typo";
import styles from "./richText.module.sass";
import utils from "../../utils";

export interface IRichText {
  document: Document;
  classes?: string;
}

const isUrlAbsolute = (url: string) =>
  url.indexOf("//") === 0
    ? true
    : url.indexOf("://") === -1
      ? false
      : url.indexOf(".") === -1
        ? false
        : url.indexOf("/") === -1
          ? false
          : url.indexOf(":") > url.indexOf("/")
            ? false
            : url.indexOf("://") < url.indexOf(".");

export const getLinkType = (url: string) => {
  const isAbsolute = isUrlAbsolute(url);
  if (isAbsolute) {
    return !url.startsWith(
      process.env.NEXT_PUBLIC_SITE_URL || "https://www.knopro.org",
    );
  }
  return false;
};

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: any) => {
      const {
        data: { uri },
      } = node;
      const { content } = node;
      const isExternalLink = getLinkType(uri);
      return (
        <a
          href={uri}
          target={`${isExternalLink ? "_blank" : "_self"}`}
          rel={`${isExternalLink ? "noopener noreferrer" : ""}`}
        >
          {content[0].value}
        </a>
      );
    },

    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      // eslint-disable-next-line react/destructuring-assignment
      const { fields, sys } = node.data.target;

      if (
        [
          "downloadable",
          "externalLinks",
          "internalLinks",
          "eventHandler",
          "deepLinking",
        ].includes(sys.contentType?.sys?.id)
      ) {
        return <Button theme={fields?.layout} link={{ fields, sys }} />;
      }
      if (["embedIFrame"].includes(sys.contentType?.sys?.id)) {
        return (
          <IFrameContainer
            src={fields?.sourceUrl}
            title={fields?.friendlyName}
            height={fields?.height}
            width={fields?.width}
          />
        );
      }
      return <Typo>Entry not supported</Typo>;
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <ul
          style={{
            listStyle: "disc",
            paddingLeft: "2ch",
            display: "inline-block",
          }}
        >
          {children}
        </ul>
      );
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => {
      return (
        <ol
          style={{
            paddingLeft: "2ch",
            display: "inline-block",
          }}
        >
          {children}
        </ol>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      /* eslint-disable react/destructuring-assignment */
      return (
        <div style={{ marginBlock: "24px" }}>
          <Image
            image={node.data.target}
            height={`min(${node.data.target.fields.file.details.image.height},auto)`}
            width={`min(${node.data.target.fields.file.details.image.width},100%)`}
          />
        </div>
      );
    },
    [BLOCKS.TABLE]: (node: any, children: any) => (
      <div className={` ${styles.tableDataContainer}`}>
        <table className={`${styles.tableContainers}`}>
          <tbody>{children}</tbody>
        </table>
      </div>
    ),
    [BLOCKS.TABLE_ROW]: (node: any, children: any) => <tr>{children}</tr>,
    [BLOCKS.TABLE_CELL]: (node: any, children: any) => <td>{children}</td>,
  },
  renderText: (text: any) => {
    /* eslint-disable react/no-array-index-key */
    return text
      .split("\n")
      .reduce((children: any[], textSegment: any, index: number) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
  },
};

const RichText: FC<IRichText> = ({ document, classes }) => {
  return (
    <div
      className={`${styles.richText} container ${classes} rich-text-container`}
    >
      {utils.richTextContent(document, renderOptions)}
    </div>
  );
};

export default RichText;
