/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import { IInformativeModalProps } from "../../../blades/informativeModal/@types";
import RichText from "../../RichText";
import Typo from "../../Typo";
import styles from "./skillbuilderPopUp.module.sass";

const SkillbuilderPopUp: FC<IInformativeModalProps> = (props) => {
  const { heading, description, footer } = props;

  return (
    <div
      className={`has-text-centered ${styles.skillbuilderPopUp} is-bgcolor-Grey`}
    >
      {heading && <Typo h2>{heading}</Typo>}
      <div>{description && <RichText document={description} />}</div>
      {footer && <RichText document={footer} />}
    </div>
  );
};

export default SkillbuilderPopUp;
