/* eslint-disable import/no-named-default */
import { useEffect, useState } from "react";

import { default as styles } from "./variables.module.sass";

export const useIsMobileViewport = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= parseInt(styles.mobile, 10));
    const handleWindowResize = () =>
      setIsMobile(window.innerWidth <= parseInt(styles.mobile, 10));
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { isMobile };
};

export const useIsTabletViewport = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    setIsTablet(window.innerWidth <= parseInt(styles.tablet, 10));
    const handleWindowResize = () =>
      setIsTablet(window.innerWidth <= parseInt(styles.tablet, 10));
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { isTablet };
};

// export default { useIsMobileViewport, useIsTabletViewport };
