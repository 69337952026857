// eslint-disable-next-line import/prefer-default-export
export enum Events {
  CREATE_PROJECT = "create:project",
  SUGGEST_CHALLENGE = "suggest:challenge",
  BECOME_PARTNER = "become:partner",
  BECOME_MENTOR = "become:mentor",
}

export enum DeepLinkingEvent {
  DEEP_LINKING_PROJECT = "deepLinking:project",
}
