/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { IExternalLinkPropsGraphQL } from "../../models/Contentful/types/siteTemplateGraphQL";

interface CExternalLinkProps {
  classes?: string;
  children?: React.ReactNode;
  showName?: boolean;
}

const ExternalLinkGraphQL: FC<
  CExternalLinkProps & IExternalLinkPropsGraphQL
> = ({
  url,
  classes = "",
  children,
  layout,
  diffTab = true,
  text = "",
  showName = "",
}) => {
  const layoutClass =
    layout === "Primary"
      ? "is-primary"
      : layout === "Secondary"
        ? "is-secondary"
        : layout === "Text"
          ? "is-text"
          : "";

  return (
    <a
      href={url}
      target={diffTab ? "_blank" : "_self"}
      rel="noreferrer"
      aria-label={text}
      className={`${classes} ${layoutClass}`}
    >
      {children}
      {!children && (showName ?? text ?? "")}
    </a>
  );
};

export default ExternalLinkGraphQL;
