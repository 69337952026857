import { FeedbackFields } from "components/FeedbackRequestList/FeedbackRequestListFilterItems";
import React, { createContext, useState, useMemo, ReactNode } from "react";

const defaultSort = () => {
  const column = FeedbackFields.find((field) => field.defaultSort);
  return {
    order: `${column?.value} ${column?.defaultSort}`,
  };
};

export interface TFilters {
  challenge_id?: string;
  status?: string;
}

interface FeedbackRequestsContextProps {
  selected: number;
  sorting: any;
  searchField: TFilters;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  setSorting: React.Dispatch<React.SetStateAction<any>>;
  setSearchField: React.Dispatch<React.SetStateAction<TFilters>>;
}

const initialState = {
  selected: 0,
  sorting: defaultSort(),
  searchField: {},
  setSelected: () => {},
  setSorting: () => {},
  setSearchField: () => {},
};

export const FeedbackRequestsContext =
  createContext<FeedbackRequestsContextProps>(initialState);

interface FeedbackRequestsProviderProps {
  children: ReactNode;
}

export const FeedbackRequestsProvider: React.FC<
  FeedbackRequestsProviderProps
> = ({ children }) => {
  const [selected, setSelected] = useState<number>(initialState.selected);
  const [sorting, setSorting] = useState<any>(initialState.sorting);
  const [searchField, setSearchField] = useState<TFilters>(
    initialState.searchField,
  );

  const contextValue = useMemo(
    () => ({
      selected,
      sorting,
      searchField,
      setSelected,
      setSorting,
      setSearchField,
    }),
    [selected, sorting, searchField],
  );

  return (
    <FeedbackRequestsContext.Provider value={contextValue}>
      {children}
    </FeedbackRequestsContext.Provider>
  );
};
