import { stripHtml } from "string-strip-html";
import { Document } from "@contentful/rich-text-types";
import utils from "../utils";
import contentfulClient from "../models/Contentful";

const entryIdDetails: Record<string, any> = {
  createAccountSkillBuilder: "72kTKR6nWRCDV5RGtxfehA",
};

const sanitize = (name: string) => {
  const sanitized = `${name}`.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  return sanitized;
};

const amountWithCommas = (amt: number) => {
  return amt?.toString().replace(/.(?=(?:.{3})+$)/g, "$&,");
};

const getStringFromHTML = (html: any) => {
  const reBody = /<main[^>]*>((.|[\n\r])*)<\/main>/.exec(html);
  const body = reBody instanceof Array && reBody.length > 0 ? reBody[0] : html;

  // get text from html, replace all svg titles containing _ with "", split the results on '\n', get the content
  const content = stripHtml(body).result;
  return content;
};

const fetchModalDetails = (entryName: string) => {
  const data = contentfulClient.getEntry(entryIdDetails[entryName], {
    include: 10,
  });
  return data;
};

const getStrippedString = (str: string | Document, stripLen = 60) => {
  const stringToCrop =
    typeof str !== "string" ? utils.richTextToPlainText(str as Document) : str;
  return {
    string: stringToCrop.substring(0, stripLen),
    charLeftCount: stringToCrop.length - stripLen,
  };
};

const convertRichTextToPlainText = (str: Document) => {
  return utils.richTextToPlainText(str as Document);
};

export {
  sanitize,
  amountWithCommas,
  getStringFromHTML,
  fetchModalDetails,
  entryIdDetails,
  getStrippedString,
  convertRichTextToPlainText,
};
