/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */
import { FC } from "react";
import clsx from "clsx";
import { IInformativeModalProps } from "../../../blades/informativeModal/@types";
import RichText from "../../RichText";
import Typo from "../../Typo";
import styles from "./challengePopUp.module.sass";

const ChallenePopUp: FC<IInformativeModalProps> = (props) => {
  const { heading, description, footer } = props;
  return (
    <div className={`has-text-centered ${styles.challengePopUp}`}>
      <div className={clsx(styles.section)}>
        <Typo h2>{heading}</Typo>
      </div>
      <div className={clsx("is-bgcolor-Grey", styles.section)}>
        {description && (
          <div className={styles.bodyContent}>
            <RichText document={description} />
          </div>
        )}
      </div>
      <div className={clsx(styles.section)}>
        {footer && <RichText document={footer} />}
      </div>
    </div>
  );
};

export default ChallenePopUp;
