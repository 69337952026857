import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { AuthContextProvider } from "./AuthProvider";
import { ContentModalProvider } from "./ContentModalProvider";
import { FeedbackRequestsProvider } from "./FeedbackRequestProvider";

const rollbarConfig = {
  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT !== "local",
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
  payload: {
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
};

const AppContextProviders: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const { children } = props;
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_URL ?? ""}
        >
          <AuthContextProvider>
            <ContentModalProvider>
              <FeedbackRequestsProvider>{children}</FeedbackRequestsProvider>
            </ContentModalProvider>
          </AuthContextProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default AppContextProviders;
