/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedTimeFormat from "dayjs/plugin/advancedFormat";
import Script from "next/script";
import { useRouter } from "next/router";
import { STORAGE_UTM_PARAMS, UtmParam } from "Constants/variables";
import { DeepLinkingEvent, Events } from "../utils/Events/@types";
import useCustomEventListener from "../utils/Events";
import AuthContext from "../AppContext/AuthProvider";
import { SeoMetaDataGraphQL } from "../models/Contentful/types/siteTemplateGraphQL";
import MetaHead from "../components/MetaHead";
import { AppPropsWithLayout } from "../types/page";
import utils from "../utils";
import {
  IStaticPageProps,
  DefaultPage,
  CSeoTemplate,
} from "../models/Contentful/types";
import AppContextProviders from "../AppContext";
import "../styles/globals.sass";
import "../../public/static/images/1.3.0/css/line-awesome.min.css";

const APP_URL = process.env.NEXT_PUBLIC_API_URL;

utils.setWithCredentials(true);
if (APP_URL) {
  utils.setBaseAPI_URL(APP_URL);
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedTimeFormat);
dayjs.tz.setDefault("America/New_York");

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { site, page } = pageProps as IStaticPageProps<unknown>;

  const router = useRouter();
  const queryParams = router.query ?? "";

  useEffect(() => {
    const currentUtmParams = JSON.parse(
      localStorage.getItem(STORAGE_UTM_PARAMS) || "{}",
    );
    // filter out the utm params from the query params
    const utmParamsArray = Object.values(UtmParam || {});
    const utmParams = Object.entries(
      (queryParams as { [key: string]: string }) || {},
    ).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (utmParamsArray.includes(key) && value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    const allFields = {
      ...currentUtmParams,
      ...utmParams,
    };
    if (Object.keys(allFields).length > 0) {
      localStorage.setItem(STORAGE_UTM_PARAMS, JSON.stringify(allFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <>
      <MetaHead
        siteTitle={site?.siteTitle}
        pageTitle={(page as unknown as DefaultPage)?.title ?? ""}
        seoMetadata={site?.seoMetadata as SeoMetaDataGraphQL}
        pageSeoMetadata={page?.seoMetadata as CSeoTemplate}
      />
      {process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ||
        (process.env.NEXT_PUBLIC_HUBSPOT_TESTING && (
          <Script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/42513758.js"
          />
        ))}
      <AppContextProviders>
        <Wrapper>{getLayout(<Component {...pageProps} />, pageProps)}</Wrapper>
      </AppContextProviders>
    </>
  );
}

export default MyApp;

const Wrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentChallengeId } = useContext(AuthContext);

  /**
   * Custom event listener application
   */
  const { init, createProjectHandler, deepLinkingProjectHandler } =
    useCustomEventListener();
  useEffect(() => {
    init();
    return () => {
      document.removeEventListener(Events.CREATE_PROJECT, createProjectHandler);
      document.removeEventListener(
        DeepLinkingEvent.DEEP_LINKING_PROJECT,
        deepLinkingProjectHandler,
      );
    };
  }, [currentChallengeId]);

  return <>{children}</>;
};
