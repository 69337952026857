import clsx from "clsx";
import React, { FC } from "react";
import Typo from "../../Typo";

export interface HelperTextProps {
  children: React.ReactNode;
  error?: boolean;
  className?: string;
}

const HelperText: FC<HelperTextProps> = (props) => {
  const { children, error, className } = props;
  return (
    <Typo
      small
      classes={clsx("helper-text", className, { "has-text-danger": error })}
    >
      {children}
    </Typo>
  );
};

export default HelperText;
