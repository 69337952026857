/* eslint-disable consistent-return */
import { ApiResponse, TTwoFactorFields } from "../@types";
import { logger, request } from "../../utils";
import User, {
  DeleteAccountData,
  ForgotPasswordFormData,
  GoogleSSO,
  LoginFormData,
  ResetPasswordFormData,
  SignUpFormData,
  UpdatePasswordFormData,
} from "./@types";
import { ROUTES } from "../../Constants/variables";

export const login = async (
  credential: Partial<LoginFormData>,
): Promise<ApiResponse<void> & Partial<TTwoFactorFields>> => {
  return request<ApiResponse<void> & Partial<TTwoFactorFields>>({
    url: "signin",
    method: "POST",
    data: credential,
  });
};

export const signUp = async (
  data: Partial<SignUpFormData>,
): Promise<ApiResponse<User>> => {
  return request<ApiResponse<User>>({
    url: "signup",
    method: "POST",
    data,
  });
};

export const forgotPassword = async (data: Partial<ForgotPasswordFormData>) => {
  return request({
    url: "forgot-password",
    method: "POST",
    data,
  });
};

export const resetPassword = async (data: Partial<ResetPasswordFormData>) => {
  return request({
    url: "reset-password",
    method: "POST",
    data,
  });
};

export const changePassword = async (data: Partial<UpdatePasswordFormData>) => {
  return request({
    url: "change-password",
    method: "PATCH",
    data,
  });
};

export const getUser = async (
  referrer?: string,
): Promise<ApiResponse<User>> => {
  try {
    return await request<ApiResponse<User>>({
      url: `me`,
      method: "GET",
    });
  } catch (error: any) {
    logger.error(error);
    // Attempting to prevent infinite spinner issue on error
    if (error.message) {
      localStorage.setItem("networkError", JSON.stringify(error));
      localStorage.removeItem("isUserAuthenticated");
      const errorUrl = `${referrer}/?error=spinner`;
      window.location.replace(errorUrl);
    }
    throw error;
  }
};

export const getGoogleUser = async (
  token: string | null,
  authType: string | any,
): Promise<ApiResponse<GoogleSSO> & Partial<TTwoFactorFields>> => {
  try {
    return await request<ApiResponse<GoogleSSO> & Partial<TTwoFactorFields>>({
      url: `google_access`,
      method: "POST",
      data: { token, authType },
    });
  } catch (error: any) {
    logger.error(error);
    if (error.message) {
      localStorage.setItem("networkError", JSON.stringify(error));
      localStorage.removeItem("isUserAuthenticated");
      const errorUrl = `${ROUTES.LOGIN}/?error=spinner`;
      window.location.replace(errorUrl);
    }
    throw error;
  }
};

export const logout = async () => {
  return request({
    url: `logout`,
    method: "POST",
  });
};

export const isUserExist = async (
  email: string,
): Promise<ApiResponse<boolean>> => {
  return request<ApiResponse<boolean>>({
    url: `users/email-exists`,
    method: "POST",
    data: { email },
  });
};

export const resendEmailVerifyLink = async (
  email: string,
): Promise<ApiResponse<boolean>> => {
  return request<ApiResponse<boolean>>({
    url: `resend-verifyEmail`,
    method: "POST",
    params: { email },
  });
};

export const deleteAccount = async (
  data: DeleteAccountData,
): Promise<User | undefined> => {
  return request<User>({
    url: `users/force-delete/${data.id}`,
    method: "DELETE",
  });
};

export const getUserName = (user: User): string | undefined => {
  if (!user.first_name && !user.last_name) return;
  if (user.first_name && user.last_name)
    return `${user.first_name} ${user.last_name}`;
  return user.first_name;
};

export const twoFactorSignIn = async ({
  email,
  code,
}: {
  email: string;
  code: string;
}): Promise<ApiResponse<void>> => {
  return request<ApiResponse<void>>({
    url: "twoFactorSignIn",
    method: "POST",
    data: {
      email,
      code,
    },
  });
};

export const reSendCode = async ({
  email,
}: {
  email: string;
}): Promise<ApiResponse<void>> => {
  return request<ApiResponse<void>>({
    url: "reSendCode",
    method: "POST",
    data: {
      email,
    },
  });
};
