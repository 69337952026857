/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-types */
import { useContext, useEffect } from "react";
import { useRouter } from "next/router";

import AuthContext from "../../AppContext/AuthProvider";
import useChallengeHeroActions from "../../components/ChallengeHero/useChallengeHeroActions";
import useGetInvolvedHook from "../../components/GetInvolved/useGetInvolvedHook";
import useProjectModal from "../../components/ProjectModal/useProjectModal";
import { DEEPLINK_CHALLENGE_ID, ROUTES } from "../../Constants/variables";
import { TChallengeModalSubType } from "../../models/Challenge/@types";
import ProjectModel from "../../models/Project";
import UserModel from "../../models/User";
import utils, { logger } from "..";
import helpers from "../helpers";
import { DeepLinkingEvent, Events } from "./@types";

function useCustomEventListener() {
  const { appUser, currentChallengeId } = useContext(AuthContext);
  const { showGetInvolveForm } = useGetInvolvedHook();

  const { modalData } = useChallengeHeroActions(currentChallengeId);
  const createProjectModal = modalData.find(
    (m) => m?.fields?.subType === TChallengeModalSubType.CREATE_PROJECT,
  );
  const { showCreateProjectModal } = useProjectModal(createProjectModal);

  const router = useRouter();

  const createProjectHandler = () => {
    if (!currentChallengeId) return;
    ProjectModel.createProjects(currentChallengeId)
      .then((res) => {
        const challengeData = localStorage.getItem("challengeData");
        if (challengeData) {
          const parsedChallenge = JSON.parse(challengeData);
          parsedChallenge.push(res.data);
          localStorage.setItem(
            "challengeData",
            JSON.stringify(parsedChallenge),
          );
        }
        router.push(
          helpers.generatePath(ROUTES.PROJECT, {
            projectId: res.data.project_id,
          }),
        );
      })
      .catch((err) => {
        logger.error(err);
      });

    // handleCreateProject(currentChallengeId, (data) => {
    //   router.push(
    //     helpers.generatePath(ROUTES.PROJECT, {
    //       projectId: data.project_id,
    //     })
    //   );
    // });
  };

  const deepLinkingProjectHandler = () => {
    if (!currentChallengeId) return;

    if (!appUser) {
      utils.setCookie(DEEPLINK_CHALLENGE_ID, currentChallengeId);
      router.push(ROUTES.LOGIN);
      return;
    }

    UserModel.getChallengesInfoForUser([currentChallengeId])
      .then((res) => {
        const challengeStatus = res.data[0].status;
        switch (challengeStatus) {
          case "no_participation": {
            showCreateProjectModal();
            break;
          }
          case "active":
          case "completed":
            if (res.data[0].project_id) {
              router.push(
                helpers.generatePath(ROUTES.PROJECT, {
                  projectId: res.data[0].project_id,
                }),
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const init = () => {
    /**
     * Event Creators
     */

    document.addEventListener(Events.CREATE_PROJECT, createProjectHandler);

    document.addEventListener(Events.SUGGEST_CHALLENGE, () => {
      showGetInvolveForm("suggest_challenge");
    });
    document.addEventListener(Events.BECOME_PARTNER, () => {
      showGetInvolveForm("become_partner");
    });
    document.addEventListener(Events.BECOME_MENTOR, () => {
      showGetInvolveForm("become_mentor");
    });
  };

  useEffect(() => {
    document.addEventListener(
      DeepLinkingEvent.DEEP_LINKING_PROJECT,
      deepLinkingProjectHandler,
    );

    return () => {
      document.removeEventListener(
        DeepLinkingEvent.DEEP_LINKING_PROJECT,
        deepLinkingProjectHandler,
      );
    };
  }, [appUser, modalData]);

  return { init, createProjectHandler, deepLinkingProjectHandler };
}

export default useCustomEventListener;
