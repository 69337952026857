import { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import axios, { AxiosRequestConfig } from "axios";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

export type TCookieKey =
  | "projectId"
  | "inviteToken"
  | "isCookieAccepted"
  | "isUserAuthenticated"
  | "deepLinkChallengeId";

export type ISlugJsonType = {
  contentType: string;
  slug: string;
  contentId: string;
};

// REQUIRE EMAIL VERIFICATION

export const REQUIRE_EMAIL_VERIFICATION = false;

export const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  if (!axios.defaults.baseURL) {
    throw new Error("Error: Base Url is not provided");
  }
  const resp = await axios.request<T>(config);
  return resp.data;
};

export const logger = console;

const utils = {
  // TODO: Rest
  imageLoader: (image: { src?: string; width?: number; quality?: number }) =>
    `${image.src}?w=${image.width}&q=${image.quality || 75}`,
  richTextContent: (document: Document, renderOptions?: any): ReactNode => {
    if (!document) return "";
    return documentToReactComponents(document, renderOptions);
  },
  richTextToPlainText: (document: Document): string => {
    return documentToPlainTextString(document);
  },
  filterUndefinedSlugs: (
    slugs: {
      params: {
        slug: string[];
      };
    }[],
  ) => {
    const filteredSlug = slugs.filter((slug) => {
      const slugPath = slug.params.slug;
      if (slugPath.includes(undefined as unknown as string)) {
        logger.error("found a page with slug undefined");
      }
      return !slugPath.includes(undefined as unknown as string);
    });

    return filteredSlug;
  },
  getSlugs: (type: { contentType: string }, slugJson: ISlugJsonType[] = []) => {
    const { contentType } = type;
    const results = slugJson.filter((item) => {
      return item.contentType === contentType;
    });
    return results;
  },
  setWithCredentials: (value: boolean) => {
    axios.defaults.withCredentials = value;
  },
  setBaseAPI_URL: (url: string): void => {
    axios.defaults.baseURL = url;
  },

  setCookie: (key: TCookieKey, data: string) => localStorage.setItem(key, data),
  getCookie: (key: TCookieKey) => localStorage.getItem(key),
  clearCookie: (key: TCookieKey) => localStorage.removeItem(key),
  downloadBlob: (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  },
  capitalizeFirstLetter: (data: string) => {
    return data.charAt(0).toUpperCase() + data.slice(1);
  },
};

export default utils;
