/* eslint-disable import/no-cycle */
import { CType } from "../Contentful/types";
import {
  CLinkPropsGraphQL,
  IRichTextGraphQL,
  WidgetCommonPropertiesGraphQL,
} from "../Contentful/types/siteTemplateGraphQL";

export type TMessagingTypes =
  | "Signup - Who you are?"
  | "Signup - Setup Login"
  | "Signup - Thank you"
  | "Forgot Password - Check Email"
  | "Tutorial - Mentor"
  | "Tutorial - Student"
  | "Tutorial - Teacher"
  | "Thank You For Project Invite"
  | "provide-feedback-thank-you"
  | "Tutorial - Challenge"
  | "Project - Submission tutorial"
  | "Project - My Team"
  | "Project - Tutorial"
  | "Project - Check your Link"
  | "Got Your Submission"
  | "no-requested-feedbacks"
  | "Tip For - Providing Feedbacks"
  | "page-not-found"
  | "GetInvolved - Thank You"
  | "ToolTip - Collaboration"
  | "ToolTip - Communication"
  | "ToolTip - Problem Solving"
  | "ToolTip - Social Awareness"
  | "Project Submission";

export type CMessagingBlade = CType<TMessagingBlade>;

export interface TMessagingBlade {
  type?: TMessagingTypes;
  heading?: string;
  subHeading?: string;
  description?: IRichTextGraphQL;
  videoUrl?: string;
  commonProperties?: WidgetCommonPropertiesGraphQL;
  links?: CLinkPropsGraphQL;
  icon?: "happy" | "enjoy" | "cry";
}

export const MessageBladeQuery = `query GetMessageBladeQuery($type:[String]!){
  messagingBladeCollection(
    where: {
      type_in: $type
    }
  ) {
    items {
      type
      heading
      subHeading
      description{
        json
      }
      videoUrl
      icon
      commonProperties {
        backgroundColor
        parentBackgroundColor
      }
      links {
        ... on InternalLinks {
          sys {
            id
            publishedAt
          },
          __typename,
          text
          layout
          referencePage {
            __typename
            ... on Pages {
              slug
            }
          }
        }
        ... on ExternalLinks {
          sys {
            id
            publishedAt
          },
          __typename,
          url
          text
          layout
          diffTab
        }
      }
    }
  }
}`;
