import React, { FC, useMemo, useState } from "react";

export interface ModalContextProps {
  open: boolean;
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
}

const ContentModalContext = React.createContext<ModalContextProps>({
  open: false,
  showModal: () => {},
  hideModal: () => {},
});

export const ContentModalProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>();

  const showModal = (content: React.ReactNode) => {
    setModalContent(content);
    setModalState(true);
  };

  const hideModal = () => {
    setModalContent(null);
    setModalState(false);
  };

  const memoedValue = useMemo(() => {
    return {
      open: modalState,
      showModal,
      hideModal,
    } as ModalContextProps;
  }, [modalState]);

  return (
    <ContentModalContext.Provider value={memoedValue}>
      {children}
      {modalState ? modalContent : null}
    </ContentModalContext.Provider>
  );
};

export default ContentModalContext;
