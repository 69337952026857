/* eslint-disable import/no-cycle */
import { useContext } from "react";
import ContentModalContext from "../../AppContext/ContentModalProvider";
import { TFormType } from "../../models/Contact/@types";
import GetInvolved from "./index";

export const useGetInvolvedHook = () => {
  const { showModal, hideModal } = useContext(ContentModalContext);
  const showGetInvolveForm = (type: TFormType) => {
    showModal(<GetInvolved isOpen onClose={hideModal} type={type} />);
  };
  return {
    showGetInvolveForm,
  };
};

export default useGetInvolvedHook;
