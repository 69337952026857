/* eslint-disable import/no-cycle */
import User from "../auth/@types";
import { IChallengeProps } from "../Challenge/@types";
import contentfulClient from "../Contentful";
import { TInvitesWithChallenge, TProject, TProjectInvites } from "../User";
import { logger } from "../../utils";
import {
  TFeedback,
  TFeedbackWithChallenge,
  TProjectWithChallenge,
} from "./@types";

export const ensureChallengeInProject = async (
  projects: TProject[],
): Promise<TProjectWithChallenge[]> => {
  if (!projects.length) return [];
  try {
    const challengeIds = projects.map((proj) => proj.challenge_id);
    const { items } = await contentfulClient.getEntries<IChallengeProps>({
      content_type: "challenge",
      "sys.id[in]": challengeIds.join(),
      include: 10,
    });
    const projWithChall = projects
      .map((p) => {
        const challenge = items.find((i) => i?.sys?.id === p.challenge_id);
        if (challenge)
          return {
            ...p,
            projectChallenge: {
              ...challenge.fields,
              entryId: challenge?.sys?.id ?? challenge?.fields?.slug,
            },
          };
        return {
          ...p,
          projectChallenge: {
            title: "Challenge not found",
            description: { content: [] },
            media: "Image",
            skills: { content: [] },
            industryList: { content: [] },
            earnPoints: 0,
            dueDate: "",
            duration: "",
            partners: [],
            slug: "",
            entryId: "",
            modals: { content: [] },
            launchDate: "",
          },
        };
      })
      .filter((f) => f) as TProjectWithChallenge[];
    return projWithChall;
  } catch (error) {
    logger.error(error);
    return [];
  }
};

export const ensureChallengeInProjectInvite = async (
  invites: TProjectInvites[],
): Promise<TInvitesWithChallenge[]> => {
  if (!invites.length) return [];
  try {
    const challengeIds = invites.map((proj) => proj.challenge_id);
    const { items } = await contentfulClient.getEntries<IChallengeProps>({
      content_type: "challenge",
      "sys.id[in]": challengeIds.join(),
      include: 10,
    });
    const inviteWithChall = invites
      .map((p) => {
        const challenge = items.find((i) => i?.sys?.id === p.challenge_id);
        if (challenge)
          return {
            ...p,
            inviteChallenge: {
              ...challenge.fields,
              entryId: challenge?.sys?.id ?? challenge?.fields?.slug,
            },
          };
        return undefined;
      })
      .filter((f) => f) as TInvitesWithChallenge[];
    return inviteWithChall;
  } catch (error) {
    logger.error(error);
    return [];
  }
};

export const ensureChallengeInFeedback = async (
  feedbacks: TFeedback[],
): Promise<TFeedbackWithChallenge[]> => {
  if (!feedbacks.length) return [];
  try {
    const challengeIds = feedbacks
      .map((f) => f.project?.challenge_id)
      .filter((f) => f) as string[];
    const challengeIdsWithoutDuplicates = new Set(challengeIds);
    const { items } = await contentfulClient.getEntries<IChallengeProps>({
      content_type: "challenge",
      "sys.id[in]": Array.from(challengeIdsWithoutDuplicates).join(),
      include: 10,
    });
    const feedbackWithChallenge = feedbacks
      .map((f) => {
        const challenge = items.find(
          (i) => i?.sys?.id === f.project?.challenge_id,
        );
        if (challenge)
          return {
            ...f,
            projectChallenge: {
              ...challenge.fields,
              entryId: challenge?.sys?.id ?? challenge?.fields?.slug,
            },
          };
        return undefined;
      })
      .filter((f) => f) as TFeedbackWithChallenge[];
    return feedbackWithChallenge;
  } catch (error) {
    logger.error(error);
    return [];
  }
};

export const isParticipant = (
  project: TProject | TProjectWithChallenge,
  user: User,
): boolean =>
  !!project.user_project?.find((p) =>
    [p.user.id, p.user_id, project.teacher_id].includes(user.id),
  );

export const hasSubmitted = (
  project: TProject | TProjectWithChallenge,
  user: User,
): boolean => {
  const userProject = project.user_project?.find(
    (p) => p.user.id === user.id || p.user_id === user.id,
  );
  if (!userProject) return false;
  return !!userProject.date_submitted;
};

export const getChallenge = async (challengeId: string) => {
  try {
    const challenge = await contentfulClient.getEntry<IChallengeProps>(
      challengeId,
      {
        content_type: "challenge",
        include: 10,
      },
    );
    return challenge;
  } catch (error) {
    logger.error(error);
    return undefined;
  }
};
