/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
import { FC } from "react";
import { camelCase } from "lodash";
import {
  CLinkPropsGraphQL,
  IExternalLinkPropsGraphQL,
  IInternalLinkPropsGraphQL,
} from "../../models/Contentful/types/siteTemplateGraphQL";
import { LinkType } from "../../models/Contentful/types";
import { AvailableIcons } from "../SvgLib/@types";
import ExternalLinkGraphQL from "./ExternalLinkGraphQL";
import InternalLinkGraphQL from "./internalLinkGraphQL";

interface LinkComponentProps {
  classes?: string;
  showPrivateLinks?: boolean;
  children?: React.ReactNode;
  icon?: AvailableIcons;
  iconClass?: string;
  showLabel?: boolean;
}

const LinkGraphQL: FC<LinkComponentProps & CLinkPropsGraphQL> = ({
  __typename,
  classes = "",
  children,
  showPrivateLinks,
  icon,
  iconClass,
  showLabel = true,
  ...fields
}) => {
  const linkType: LinkType = camelCase(__typename) as LinkType;
  if (linkType === "externalLinks")
    return (
      <ExternalLinkGraphQL
        {...(fields as IExternalLinkPropsGraphQL)}
        classes={classes}
        showName={showLabel}
      >
        {children}
      </ExternalLinkGraphQL>
    );
  if (linkType === "internalLinks") {
    return (
      <InternalLinkGraphQL
        {...(fields as IInternalLinkPropsGraphQL)}
        classes={classes}
        showPrivateLinks={showPrivateLinks}
        iconName={icon}
        iconClass={iconClass}
      >
        {children}
      </InternalLinkGraphQL>
    );
  }

  return null;
};
export default LinkGraphQL;
