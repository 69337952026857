import React, { FC } from "react";

import Script from "next/script";
import SectionWrapper from "../SectionWrapper";
import styles from "./iFrameContainer.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface IIFrameContainerProps {
  src: string;
  title: string;
  height?: string;
  width?: string;
}
const IFrameContainer: FC<IIFrameContainerProps> = (props) => {
  const { src, title, height, width } = props;
  const { isMobile } = useIsMobileViewport();
  const aspectRatio = isMobile ? "1/1" : "16/9";
  const aspectRatioNum =
    typeof aspectRatio === "string"
      ? parseInt(aspectRatio.split("/")[0], 10) /
        parseInt(aspectRatio.split("/")[1], 10)
      : aspectRatio;

  return (
    // eslint-disable-next-line react/button-has-type
    <SectionWrapper>
      <div
        className={`${styles.iFrameContainer} is-flex my-3`}
        style={{
          minHeight: height && height,
          maxWidth: width && width,
        }}
      >
        <iframe
          src={src}
          title={title}
          allowFullScreen
          loading="lazy"
          style={{
            aspectRatio: aspectRatioNum.toString(),
            margin: "0 auto",
          }}
          className={`${styles.iFrameContainer} container is-flex`}
        />
      </div>
      <Script src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js" />
    </SectionWrapper>
  );
};

export default IFrameContainer;
