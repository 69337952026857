import { ApiResponse } from "../@types";
import { request } from "../../utils";
import { TFormProps, TFormType } from "./@types";

export const submitGetInvolved = (type: TFormType, data: TFormProps) => {
  return request<ApiResponse<any>>({
    url: `involve/get-involved`,
    method: "POST",
    data: { ...data, type },
  });
};

export default {};
