import { useContext, useEffect, useState } from "react";
import AuthContext from "../../AppContext/AuthProvider";
import { IInformativeModalProps } from "../../blades/informativeModal/@types";
import contentfulClient from "../../models/Contentful";
import { CType } from "../../models/Contentful/types";
import { ChallengeNSkillBuilderStatusForUser } from "../../models/User";
import { logger } from "../../utils";

function useChallengeHeroActions(challengeId?: string) {
  const { appUser, challengeData } = useContext(AuthContext);
  useEffect(() => {
    getChallengeStatusForUser();
    getModalData();
    // challengeJoinedCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeId, appUser]);

  const [modalData, setModalData] = useState<CType<IInformativeModalProps>[]>(
    [],
  );
  // const [isChallengeJoined, setIsChallengeJoined] = useState(false);
  const [challengeStatus, setChallengeStatus] =
    useState<ChallengeNSkillBuilderStatusForUser>();
  const [projectIdForChallenge, setProjectIdForChallenge] = useState("");
  useState<ChallengeNSkillBuilderStatusForUser>();
  const getModalData = async () => {
    try {
      const res = await contentfulClient.getEntries<IInformativeModalProps>({
        content_type: "informativeModal",
        "fields.type[in]": "Challenge PopUp",
        include: 10,
      });
      setModalData(res.items);
    } catch (error) {
      logger.error(error);
    }
  };
  // const challengeJoinedCheck = async () => {
  //   try {
  //     const { data } = await ProjectModel.isChallengeJoined(challengeId ?? "");
  //     setIsChallengeJoined(data.alreadyExists);
  //   } catch (error) {
  //     logger.error(error);
  //   }
  // };

  const getChallengeStatusForUser = async () => {
    try {
      let challenges = challengeData;
      const localChallenges = localStorage.getItem("challengeData");
      if (localChallenges) {
        challenges = JSON.parse(localChallenges);
      }
      if (challenges) {
        if (/*! appUser || */ !challengeId) return;
        // const { data } = await UserModel.getChallengesInfoForUser([challengeId]);
        const challengeFinder = challenges.find(
          (x: any) => x.challenge_id === challengeId,
        );
        if (challengeFinder) {
          setChallengeStatus(challengeFinder.status);
          if (challengeFinder.project_id) {
            setProjectIdForChallenge(challengeFinder.project_id);
          }
        } else {
          setChallengeStatus("no_participation");
        }
      }
    } catch (error) {
      logger.error(error);
    }
  };
  return {
    modalData,
    // isChallengeJoined,
    challengeStatus,
    projectIdForChallenge,
  };
}

export default useChallengeHeroActions;
