/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import { FC } from "react";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";

import Button from "../Button";
import IFrameContainer from "../IFrameContainer";
import Image from "../Image";
import Typo from "../Typo";
import { getLinkType } from "../RichText";
import styles from "../RichText/richText.module.sass";
import utils from "../../utils";

export interface IRichTextGraphQL {
  json: Document;
  links: any;
  classes?: string;
}

function renderOptions(links: any) {
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  if (links?.assets?.block) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }
  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  if (links?.entries?.block) {
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  // loop through the inline linked entries and add them to the map
  if (links?.entries?.inline) {
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }
  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        const {
          data: { uri },
        } = node;
        const { content } = node;
        const isExternalLink = getLinkType(uri);
        return (
          <a
            href={uri}
            target={`${isExternalLink ? "_blank" : "_self"}`}
            rel={`${isExternalLink ? "noopener noreferrer" : ""}`}
          >
            {content[0].value}
          </a>
        );
      },

      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        // eslint-disable-next-line react/destructuring-assignment
        const entry = entryMap.get(node.data.target.sys.id);
        if (
          [
            "downloadable",
            "externalLinks",
            "internalLinks",
            "eventHandler",
            "deepLinking",
          ].includes(entry?.__typename)
        ) {
          return <Button theme={entry?.layout}>inline to be done</Button>;
        }
        if (["EmbedIFrame"].includes(entry?.__typename)) {
          return (
            <IFrameContainer
              src={entry?.sourceUrl}
              title={entry?.friendlyName}
              height={entry?.height}
              width={entry?.width}
            />
          );
        }
        return <Typo>Entry not supported</Typo>;
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => {
        return (
          <ul
            style={{
              listStyle: "disc",
              paddingLeft: "2ch",
              display: "inline-block",
            }}
          >
            {children}
          </ul>
        );
      },
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return (
          <ol
            style={{
              paddingLeft: "2ch",
              display: "inline-block",
            }}
          >
            {children}
          </ol>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const asset = assetMap.get(node.data.target.sys.id);
        return (
          <div style={{ marginBlock: "24px" }}>
            <Image
              image={asset.url}
              height={`min(${asset.height},auto)`}
              width={`min(${asset.width},100%)`}
            />
          </div>
        );
      },
      [BLOCKS.TABLE]: (node: any, children: any) => (
        <div className={` ${styles.tableDataContainer}`}>
          <table className={`${styles.tableContainers}`}>
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node: any, children: any) => <tr>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node: any, children: any) => <td>{children}</td>,
    },
  };
}

const RichTextComponentGraphQL: FC<IRichTextGraphQL> = ({
  json,
  links,
  classes,
}) => {
  return (
    <div
      className={`${styles.richText} container ${classes} rich-text-container`}
    >
      {utils.richTextContent(json, renderOptions(links))}
    </div>
  );
};

export default RichTextComponentGraphQL;
