/* eslint-disable prettier/prettier */
import clsx from "clsx";
import React, { FC } from "react";

export interface ITypoProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  big?: boolean;
  medium?: boolean;
  normal?: boolean;
  small?: boolean;
  bold?: boolean;
  light?: boolean;
  classes?: string;
  handleTextOverflow?: boolean;
  children?: React.ReactNode;
  limitLines?: number;
  id?: string;
  dangerouslySetInnerHTML?: React.DOMAttributes<
    HTMLHeadingElement | HTMLSpanElement | HTMLParagraphElement
  >["dangerouslySetInnerHTML"];
  whiteSpace?: React.CSSProperties["whiteSpace"];
}

const getSizeClass = (props: ITypoProps) => {
  const { h1, h2, h3, h4, h5, h6, big, medium, normal, small } = props;
  if (h1) return `is-size-1`;
  if (h2) return `is-size-2`;
  if (h3) return `is-size-3`;
  if (h4) return `is-size-4`;
  if (h5) return `is-size-5`;
  if (h6) return `is-size-6`;
  if (big) return `is-size-7`;
  if (medium) return `is-size-8`;
  if (normal) return `is-size-9`;
  if (small) return `is-size-10`;
  return "";
};

const getTagName = (props: ITypoProps): string => {
  const { h1, h2, h3, h4, h5, h6, big, medium, normal, small } = props;
  if (h1) return "h1";
  if (h2) return "h2";
  if (h3) return "h3";
  if (h4) return "h4";
  if (h5) return "h5";
  if (h6) return "h6";
  if (big || small || medium || normal) return "span";
  return "p";
};

const Typo: FC<ITypoProps> = (props) => {
  const {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    handleTextOverflow,
    bold,
    light,
    classes,
    children,
    limitLines,
    id,
    whiteSpace,
    dangerouslySetInnerHTML,
  } = props;
  const cls = clsx(
    getSizeClass(props),
    bold ? "has-text-weight-bold" : "",
    light ? "has-text-weight-light" : "",
    handleTextOverflow ? "text-overflow" : "",
    h1 || h2 || h3 || h4 || h5 || h6
      ? "is-family-secondary"
      : "is-family-primary",
    classes
  );
  const limitLinesCss: React.CSSProperties = limitLines
    ? {
        lineClamp: limitLines,
        WebkitLineClamp: limitLines,
        display: "-webkit-box",
        overflow: "hidden",
        boxOrient: "vertical",
        MozBoxOrient: "vertical",
        WebkitBoxOrient: "vertical",
      }
    : {};
  const styles: React.CSSProperties = { ...limitLinesCss, whiteSpace };
  const tagName = getTagName(props);
  const elementProps: Record<string, any> = {
    className: cls,
    style: styles,
    id,
  };
  if (dangerouslySetInnerHTML)
    elementProps.dangerouslySetInnerHTML = dangerouslySetInnerHTML;
  const Element = React.createElement(tagName, elementProps, children);
  return Element;
};

export default Typo;
