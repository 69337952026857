/* eslint-disable import/no-cycle */
import { logger } from "../../utils";
import ChallengePopUp from "../../components/PopUps/challengePopUp";
import SkillbuilderPopUp from "../../components/PopUps/skillbuilderPopUp";
import { CType } from "../../models/Contentful/types";
import { IInformativeModalProps } from "./@types";

const InformativeModalMapping: Record<string, any> = {
  ChallengePopUp,
  SkillbuilderPopUp,
};

const InformativeModal: React.FC<CType<IInformativeModalProps>> = (props) => {
  try {
    const {
      fields: { type, ...data },
    } = props;
    const sanitizeData = type.replace(/\s/g, "");
    let ComponentType;
    switch (type) {
      case "Challenge PopUp":
        ComponentType = InformativeModalMapping[sanitizeData];
        break;
      case "Auth PopUp":
        break;
      case "Skillbuilder PopUp":
        ComponentType = InformativeModalMapping[sanitizeData];
        break;
      default:
        break;
    }
    return <ComponentType {...data} />;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
export default InformativeModal;
