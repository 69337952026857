/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";

import { AvailableIcons } from "../SvgLib/@types";
import Button from "../Button";
import { TFieldNames } from "./@types";
import styles from "./feedbackRequestList.module.sass";

export type TSort = "asc" | "desc";
interface IUserListFilterItems {
  onSortChange: (fieldName: TFieldNames, sortOrder: TSort) => void;
  sortColumn?: TFieldNames;
  sortDirection?: TSort;
}


const Sort: Record<string, TSort> = {
  ASC: "asc",
  DESC: "desc",
};


interface IMessageFields {
  id: number;
  name: string;
  value: TFieldNames;
  sortable?: boolean;
  defaultSort?: TSort;
}

export const FeedbackFields: IMessageFields[] = [
  {
    id: 0,
    name: "Date",
    value: "created_at",
    defaultSort: Sort.DESC,
    sortable: true,
  },
  {
    id: 1,
    name: "Challenge",
    value: "challenge",
  },
  {
    id: 2,
    name: "Status",
    value: "is_responded",
  },
  {
    id: 3,
    name: "Message",
    value: "message",
  },
];

const defaultSortColumn = FeedbackFields.find((field) => field.defaultSort) || { defaultSort: Sort.DESC, value: "created_at" };

const SortIconMap: Record<string, AvailableIcons> = {
  [Sort.ASC]: "arrow-up",
  [Sort.DESC]: "arrow-down",
};

const FeedbackRequestListFilterItems: FC<IUserListFilterItems> = ({ sortColumn: initialSortColumn, sortDirection, onSortChange }) => {
  const [sort, setSort] = useState<TSort>(sortDirection || defaultSortColumn.defaultSort || Sort.DESC);
  const [sortColumn, setSortColumn] = useState<TFieldNames>(initialSortColumn || defaultSortColumn.value);

  const handleSort = async (value: TFieldNames) => {
    if (sortColumn !== value) {
      setSort(Sort.DESC);
      onSortChange(value, Sort.DESC);
      setSortColumn(value);
      return;
    }
    const newSort = sort === Sort.ASC ? Sort.DESC : Sort.ASC;
    setSort(newSort);
    onSortChange(value, newSort);
  };


  return (
    <tr className={styles.tablerow}>
      {FeedbackFields.map(({ id, name, value, sortable, defaultSort }) => {
        let headerIcon: AvailableIcons = "arrow-down";
        if (sortColumn === value) {
          headerIcon = SortIconMap[sort];
        } else if (defaultSort) {
          headerIcon = SortIconMap[defaultSort];
        }
        return (
          <td key={id}>
            <div className={styles.dropDown}>
              {sortable ? (
                <Button
                  theme="Transparent - Blue Border"
                  icon={headerIcon}
                  onClick={() => {
                    handleSort(value);
                  }}
                >
                  {name}
                </Button>
              ): (
                <span className={styles.header}>{name}</span>
              )}
            </div>
          </td>
        );
      })}
      <td className={styles.action}>
        Actions
      </td>
    </tr>
  );
};

export default FeedbackRequestListFilterItems;
