/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from "react";
import { TMessagesData, getMessageData } from "../../models/Message/api";

import AuthThankYou from "../Auth/AuthThankYou";
import BecomeMentor from "./BecomeMentor";
import BecomePartner from "./BecomePartner";
import ContactModel from "../../models/Contact";
import { TFormProps } from "../../models/Contact/@types";
import { IGetInvolvedProps } from "./@types";
import ModalWrapper from "../ModalWrapper";
import SuggestChallenge from "./SuggestChallenge";
import styles from "./forms.module.sass";

const GetInvolved: FC<IGetInvolvedProps> = ({ isOpen, onClose, type }) => {
  const [thankyouMessage, setThankyouMessage] = useState<TMessagesData>(
    {} as TMessagesData,
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  useEffect(() => {
    getMessageData(["GetInvolved - Thank You"]).then(setThankyouMessage);
  }, []);

  const onFormSubmit = async (values: TFormProps) => {
    await ContactModel.submitGetInvolved(type, values);
    setIsFormSubmitted(true);
  };
  if (isFormSubmitted) {
    const message = thankyouMessage["GetInvolved - Thank You"];
    if (!message) return null;
    return (
      <ModalWrapper isActive={isOpen} onClose={onClose} hasCloseButton>
        <AuthThankYou {...message} />
      </ModalWrapper>
    );
  }
  return (
    <ModalWrapper isActive={isOpen} onClose={onClose} hasCloseButton>
      <div className={styles.root}>
        <div className={styles.formContent}>
          {type === "suggest_challenge" && (
            <SuggestChallenge onSubmit={onFormSubmit} />
          )}
          {type === "become_partner" && (
            <BecomePartner onSubmit={onFormSubmit} />
          )}
          {type === "become_mentor" && <BecomeMentor onSubmit={onFormSubmit} />}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default GetInvolved;
